// local API call base URL "http://localhost:7000/api/v1"
// staging API call base URL "https://api.auradine.gold/v1/customer"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const MUI_LICENSE_KEY =
  "6786996b2ab638cc3a4211570da06e30Tz05ODU0MSxFPTE3NTg0NzY2ODIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==";

const env = {
  REACT_APP_AUTH0_DOMAIN:
    process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID:
    process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUDIENCE: process.env.REACT_APP_AUDIENCE,
  REACT_FLUX_VISION: process.env.REACT_FLUX_VISION,
  MUI_LICENSE_KEY: MUI_LICENSE_KEY,

  APIPath: {
    jobs: API_BASE_URL + "/jobs",
    user: API_BASE_URL + "/user",
    userWithSubscription:
      API_BASE_URL + "/userWithSubscription",
    userNew: API_BASE_URL + "/usernew",    
    userAPINew: API_BASE_URL + "/userAPINew",
    getUserAPIKey: API_BASE_URL + "/getNewAPIKey",
    preference: API_BASE_URL + "/preference",
    preferenceNew: API_BASE_URL + "/preferenceNew",
    handleAPIKey: API_BASE_URL + "/handleAPIKey",
    siteLists: API_BASE_URL + "/siteList2.0",
    addSite: API_BASE_URL + "/addSite",
    usertechsupport: API_BASE_URL + "/usertechsupport",
    usertechsupportfilter:
      API_BASE_URL + "/usertechsupportfilter",
    invite: API_BASE_URL + "/invite",
    domains: API_BASE_URL + "/domains",
    org: API_BASE_URL + "/org",
    orgtechsupport: API_BASE_URL + "/orgtechsupport",
    getdgorgconfig: API_BASE_URL + "/getdgorgconfig",
    getsiteorgconfig: API_BASE_URL + "/getsiteorgconfig",
    postdgorgconfig: API_BASE_URL + "/postdgorgconfig",
    postsiteorgconfig: API_BASE_URL + "/postsiteorgconfig",
    postmultidgorgconfig:
      API_BASE_URL + "/postmultidgorgconfig",
    postmultisiteorgconfig:
      API_BASE_URL + "/postmultisiteorgconfig",
    otheruser: API_BASE_URL + "/otheruser",
    device: API_BASE_URL + "/device",
    deviceNew: API_BASE_URL + "/deviceNew",
    chipStats: API_BASE_URL + "/chipstats",
    deviceOperation: API_BASE_URL + "/deviceOperation",
    updateRunTimeDB: API_BASE_URL + "/updateRunTimeDB",
    privacy: API_BASE_URL + "/privacy",
    summary: API_BASE_URL + "/summary",
    cloudcommand: API_BASE_URL + "/commands",
    groupcommands: API_BASE_URL + "/groupcommands",
    alertNotificationConfig:
      API_BASE_URL + "/alertNotificationConfig",
    checkdatabase: API_BASE_URL + "/cmdrespserials",
    validcommands: API_BASE_URL + "/validcmds",
    devicelist: API_BASE_URL + "/devicelist",
    dglist: API_BASE_URL + "/dglist",
    export: API_BASE_URL + "/export",
    isOperationAllowed:
      API_BASE_URL + "/isOperationAllowed",
    isPoolOperationAllowed:
      API_BASE_URL + "/isPoolOperationAllowed",
    dgdevices: API_BASE_URL + "/getdgdevices",
    orgdevices: API_BASE_URL + "/getorgdevices",
    dgconfig: API_BASE_URL + "/dgconfig",
    siteconfig: API_BASE_URL + "/siteconfig",
    multidgconfig: API_BASE_URL + "/multidgconfig",
    multisiteconfig: API_BASE_URL + "/multisiteconfig",
    dgtgtTHs: API_BASE_URL + "/dgtgtTHs",
    dgoptTHs: API_BASE_URL + "/dgoptTHs",
    dgtgtPower: API_BASE_URL + "/dgtgtPower",
    dgautoTemparture: API_BASE_URL + "/dgautoTemparture",
    dgisTgtHR: API_BASE_URL + "/dgisTgtHR",
    dgramp: API_BASE_URL + "/dgramp",
    getdginfo: API_BASE_URL + "/getdginfo",
    dgview: API_BASE_URL + "/dgview",
    siteview: API_BASE_URL + "/siteview",
    dgnames: API_BASE_URL + "/dgnames",
    sitenames: API_BASE_URL + "/sitenames",
    dguseHR: API_BASE_URL + "/dguseHR",
    btc: API_BASE_URL + "/btc",
    manualEfficiencyTune:
      API_BASE_URL + "/manualEfficiencyTune",
    manualPowerCurtailSchedule:
      API_BASE_URL + "/manualPowerCurtailSchedule",
    manualPowerCurtailNow:
      API_BASE_URL + "/manualPowerCurtailNow",
    powerCurtailSchedule:
      API_BASE_URL + "/powerCurtailSchedule",
    groupCurtailData: API_BASE_URL + "/groupCurtailData",
    autotune: API_BASE_URL + "/autotune",
    onboard: API_BASE_URL + "/onboard",
    importDevices: API_BASE_URL + "/importDevices",
    deregisterDevices: API_BASE_URL + "/deregisterDevices",
    removeOrgTechsupport:
      API_BASE_URL + "/removeOrgTechsupport",
    removeDevices: API_BASE_URL + "/removeDevices",
    addDGmember: API_BASE_URL + "/addDGmember",
    // deleteDGmember: API_BASE_URL + "/deleteDGmember",
    moveDGmember: API_BASE_URL + "/moveDGmember",
    moveSitemember: API_BASE_URL + "/moveSitemember",
    minerTechsupport: API_BASE_URL + "/minerTechsupport",
    getTSJobStatus: API_BASE_URL + "/getTSJobStatus",
    getCommandStatus: API_BASE_URL + "/getCommandStatus",
    addDG: API_BASE_URL + "/addDG",
    deleteDG: API_BASE_URL + "/deleteDG",
    addSite: API_BASE_URL + "/addSite",
    deleteSite: API_BASE_URL + "/deleteSite",
    addCustomNames: API_BASE_URL + "/addCustomNames",
    addCSTags: API_BASE_URL + "/addCSTags",
    autoTuneV2Config: API_BASE_URL + "/autoTuneV2Config",
    getAutoTuneV2Config:
      API_BASE_URL + "/getAutoTuneV2Config",
    getAllAutoTuneV2Config:
      API_BASE_URL + "/getAllAutoTuneV2Config",
    attachAutoTuneV2Config:
      API_BASE_URL + "/attachAutoTuneV2Config",
    getEnergyPrice: API_BASE_URL + "/getEnergyPrice",
    getHashPrice: API_BASE_URL + "/getHashPrice",
    desiredAlertIntervalCSTeam:
      API_BASE_URL + "/desiredAlertIntervalCSTeam",
  },
};

export { env };
