import React from "react";
import useDemandResponseContext from "../../context/useDemandResponseContext";
import useAppContextProvider from "../../../AppContext/useAppContextProvider";
import { StyledDialog } from "../../../components/StyledComponents/Dialog/Dialog";
import {
  H6HeadlineSmall,
  H8TitleMedium,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  PrimaryButton,
  StyledIconButton,
  StyledToggleButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  DialogActions,
  FormControl,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PlaceHolderButton from "../../../components/PlaceHolderButton/PlaceHolderButton.js";
import CheckIcon from "@mui/icons-material/Check";
import {
  StyledFormControl,
  StyledTextField,
  StyledPaper,
} from "../../../components/StyledComponents/Inputs/Inputs.js";

const AddDemandConfigModalHeader = (props) => {
  const { openQSEDialog, setOpenQSEDialog } =
    useDemandResponseContext();
  const { reset } = props;
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      flexGrow={1}
      padding={"1rem 0"}
    >
      <H6HeadlineSmall>
        Assign a Qualified Scheduling Entity
      </H6HeadlineSmall>
      <StyledIconButton
        onClick={() => {
          setOpenQSEDialog(false);
          reset();
        }}
      >
        <CloseIcon />
      </StyledIconButton>
    </Box>
  );
};

const AssignQSEDialog = () => {
  const { openQSEDialog, setOpenQSEDialog } =
    useDemandResponseContext();
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();
  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      siteConfig: [],
      qse: null,
      username: "",
      password: "",
      rampUpDownTime: null,
    },
  });
  const siteOptions = [
    "site1",
    "site2",
    "site3",
    "site4",
    "site5",
    "site6",
    "site7",
    "site8",
    "site9",
    "site10",
  ];
  const onsubmit = (data) => {
    console.log(data);
    setOpenQSEDialog(false);
    setToastOpen(true);
    setToastMessage(
      "QSE configured successfully for the sites"
    );
    reset();
  };

  const selectedOption = watch("siteConfig");
  const selectedQSE = watch("qse");
  const enteredRampUpDownTime = watch("rampUpDownTime");
  const handleSiteConfigDeletion = (optionToDelete) => {
    const newValue = selectedOption.filter(
      (option) => option !== optionToDelete
    );
    setValue("siteConfig", newValue);
  };

  return (
    <StyledDialog
      maxWidth={"md"}
      fullWidth
      open={openQSEDialog}
      hideBackdrop
    >
      <AddDemandConfigModalHeader reset={reset} />
      <form onSubmit={handleSubmit(onsubmit)}>
        <Box
          display={"grid"}
          gridTemplateRows={"auto auto auto"}
          rowGap={7.75}
        >
          <Box
            display={"grid"}
            rowGap={4}
            gridTemplateRows={"auto auto auto"}
            className="sites-container"
          >
            <H8TitleMedium>
              Select Sites to Assign Demand Response
              Configuration
            </H8TitleMedium>
            <StyledFormControl fullWidth margin="none">
              <Controller
                name="siteConfig"
                control={control}
                defaultValue={null}
                render={({
                  field: { onChange, value, ref },
                }) => (
                  <Autocomplete
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    onChange={(_, data) => onChange(data)}
                    value={value || []}
                    clearIcon={null}
                    options={siteOptions} // Replace with your options
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        label="Site Name"
                        variant="outlined"
                        inputRef={ref}
                        sx={{ minHeight: "2.5rem" }}
                      />
                    )}
                    renderTags={() => null}
                  />
                )}
              />
            </StyledFormControl>

            <Box
              display={"grid"}
              gridTemplateColumns={
                "repeat(auto-fill, minmax(6rem, 1fr))"
              }
              gap={4}
              maxWidth={"40rem"}
            >
              {selectedOption?.map((site, index) => (
                <PlaceHolderButton
                  key={index}
                  onClose={() =>
                    handleSiteConfigDeletion(site)
                  }
                  text={site}
                />
              ))}
            </Box>
          </Box>
          <Box
            display={"grid"}
            gridTemplateRows={"auto auto"}
            rowGap={4}
          >
            <H8TitleMedium>
              Select Qualified Scheduling Entity
            </H8TitleMedium>
            <Box
              display={"grid"}
              gridTemplateColumns={
                "repeat(auto-fill, minmax(6rem,1fr) )"
              }
              gap={4}
            >
              <Controller
                name="qse"
                control={control}
                defaultValue={null}
                render={({
                  field: { value, onChange },
                }) => (
                  <StyledToggleButton
                    value={"Voltus"}
                    onClick={() =>
                      onChange(
                        selectedQSE === "Voltus"
                          ? null
                          : "Voltus"
                      )
                    }
                    selected={selectedQSE === "Voltus"}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    {selectedQSE === "Voltus" ? (
                      <CheckIcon fontSize={"small"} />
                    ) : (
                      ""
                    )}{" "}
                    Voltus
                  </StyledToggleButton>
                )}
              />

              <Controller
                name="qse"
                control={control}
                defaultValue={null}
                render={({
                  field: { value, onChange },
                }) => (
                  <StyledToggleButton
                    value={"CPower"}
                    onClick={() =>
                      onChange(
                        selectedQSE === "CPower"
                          ? null
                          : "CPower"
                      )
                    }
                    selected={selectedQSE === "CPower"}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    {selectedQSE === "CPower" ? (
                      <CheckIcon fontSize={"small"} />
                    ) : (
                      ""
                    )}{" "}
                    CPower
                  </StyledToggleButton>
                )}
              />
            </Box>
          </Box>
          {selectedQSE === "CPower" && (
            <>
              <Box
                display={"grid"}
                gridTemplateRows={"auto auto"}
                gap={4}
              >
                <H8TitleMedium>Username</H8TitleMedium>
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: "Username is required",
                  }}
                  render={({ field }) => (
                    <StyledTextField
                      margin="none"
                      type="username"
                      variant="outlined"
                      label="Username"
                      {...field}
                      error={Boolean(errors.username)}
                      helperText={
                        errors.username
                          ? errors.username.message
                          : ""
                      }
                    />
                  )}
                />
              </Box>
              <Box
                display={"grid"}
                gridTemplateRows={"auto auto"}
                gap={4}
              >
                <H8TitleMedium>Password</H8TitleMedium>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required",
                  }}
                  render={({ field }) => (
                    <StyledTextField
                      margin="none"
                      type="password"
                      variant="outlined"
                      label="Password"
                      {...field}
                      error={Boolean(errors.password)}
                      helperText={
                        errors.password
                          ? errors.password.message
                          : ""
                      }
                    />
                  )}
                />
              </Box>
            </>
          )}
          <Box
            display={"grid"}
            gridTemplateRows={"auto auto"}
            gap={4}
          >
            <H8TitleMedium>Ramp Up/Down Time</H8TitleMedium>
            <Controller
              name="rampUpDownTime"
              control={control}
              rules={{
                validate: (value) => {
                  if (!value) return true;
                  if (value < 120 || value > 86400) {
                    return "Power should be between 120 and 86,400";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <StyledTextField
                  margin="none"
                  variant="outlined"
                  label="In seconds"
                  {...field}
                  error={Boolean(errors.rampUpDownTime)}
                  helperText={
                    errors.rampUpDownTime
                      ? errors.rampUpDownTime.message
                      : "Enter a number between 120 and 86,400"
                  }
                />
              )}
            />
          </Box>
        </Box>
        <DialogActions sx={{ paddingTop: "1rem" }}>
          <PrimaryButton type="submit">Save</PrimaryButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

export default AssignQSEDialog;
