import { set } from "date-fns";
import React, {
  createContext,
  useMemo,
  useState,
} from "react";
import { AddAccessListsDialog } from "../Components/AddAccessListsDialog/AddAccessListsDialog";

export const PoolsPageContext = createContext();

const poolConfigurationMockData = [
  {
    id: "site1",
    label: "Site 1",
    pools: [
      {
        url: "url1",
        username: "username1",
        password: "password1",
        workIdentifier: "workIdentifier1",
      },
      {
        url: "url2",
        username: "username2",
        password: "password2",
        workIdentifier: "workIdentifier2",
      },
      {
        url: "url3",
        username: "username3",
        password: "password3",
        workIdentifier: "workIdentifier3",
      },
    ],
  },
  {
    id: "site2",
    label: "Site 2",
    pools: [],
  },
];

const testResult = Array.from(
  { length: 100 },
  (_, index) => ({
    id: index + 1,
    user: `miningRobot${index + 1}`,
    url: `http://stratum${index + 1}`,
  })
);

export const PoolsPageContextProvider = (props) => {
  const [tableGroup, setTableGroup] = useState({});
  const [detailGroup, setDetailGroup] = useState({});

  const [treeOpen, setTreeOpen] = React.useState(false);
  const [siteSelected, setSiteSelected] = useState(false);
  const [siteSelectedName, setSiteSelectedName] =
    useState("");
  const [groupSelectedName, setGroupSelectedName] =
    useState("");
  const [openAddConfigModal, setOpenAddConfigModal] =
    useState(false);
  const [mockData, setMockData] = React.useState([
    ...poolConfigurationMockData,
  ]);
  const [poolDataToEdit, setPoolDataToEdit] =
    React.useState({});

  const [editModeOn, setEditModeOn] = React.useState(false);
  const [deletionDialogMessage, setDeletionDialogMessage] =
    useState("");
  const [deletionDialogOpen, setDeletionDialogOpen] =
    useState(false);
  const [
    deleteDialogHeaderMessage,
    setDeleteDialogHeaderMessage,
  ] = useState("");
  // whiteLists state management

  const [accessLists, setAccessLists] = useState([
    ...testResult,
  ]);
  const [accessListsCopy, setAccessListsCopy] = useState([
    ...testResult,
  ]);
  const [editWhiteListsOn, setEditWhiteListsOn] =
    useState(false);

  const [deletionHeaderMessage, setDeletionHeaderMessage] =
    useState("");
  const [
    deletionSubHeaderMessage,
    setDeletionSubHeaderMessage,
  ] = useState("");
  const [
    openWhiteListsDeletionDialog,
    setOpenWhiteListsDeletionDialog,
  ] = useState(false);

  const [whiteListToDelete, setWhiteListToDelete] =
    useState(null);

  const [openAccessListsDialog, setOpenAccessListsDialog] =
    useState(false);

  const contextValue = useMemo(() => {
    return {
      tableGroup,
      setTableGroup,
      detailGroup,
      setDetailGroup,
      treeOpen,
      setTreeOpen,
      siteSelected,
      setSiteSelected,
      siteSelectedName,
      setSiteSelectedName,
      groupSelectedName,
      setGroupSelectedName,
      openAddConfigModal,
      setOpenAddConfigModal,
      mockData,
      setMockData,
      poolDataToEdit,
      setPoolDataToEdit,
      editModeOn,
      setEditModeOn,
      deleteDialogHeaderMessage,
      setDeleteDialogHeaderMessage,
      deletionDialogMessage,
      setDeletionDialogMessage,
      deletionDialogOpen,
      setDeletionDialogOpen,
      accessLists,
      setAccessLists,
      accessListsCopy,
      setAccessListsCopy,
      editWhiteListsOn,
      setEditWhiteListsOn,
      deletionHeaderMessage,
      setDeletionHeaderMessage,
      deletionSubHeaderMessage,
      setDeletionSubHeaderMessage,
      openWhiteListsDeletionDialog,
      setOpenWhiteListsDeletionDialog,
      whiteListToDelete,
      setWhiteListToDelete,
      openAccessListsDialog,
      setOpenAccessListsDialog,
    };
  }, [
    tableGroup,
    detailGroup,
    treeOpen,
    siteSelected,
    siteSelectedName,
    groupSelectedName,
    openAddConfigModal,
    mockData,
    poolDataToEdit,
    setPoolDataToEdit,
    setMockData,
    setTableGroup,
    setDetailGroup,
    setTreeOpen,
    setSiteSelected,
    setSiteSelectedName,
    setGroupSelectedName,
    setOpenAddConfigModal,
    editModeOn,
    setEditModeOn,
    deleteDialogHeaderMessage,
    setDeleteDialogHeaderMessage,
    deletionDialogMessage,
    setDeletionDialogMessage,
    deletionDialogOpen,
    setDeletionDialogOpen,
    accessLists,
    setAccessLists,
    accessListsCopy,
    setAccessListsCopy,
    editWhiteListsOn,
    setEditWhiteListsOn,
    deletionHeaderMessage,
    setDeletionHeaderMessage,
    deletionSubHeaderMessage,
    setDeletionSubHeaderMessage,
    openWhiteListsDeletionDialog,
    setOpenWhiteListsDeletionDialog,
    whiteListToDelete,
    setWhiteListToDelete,
    openAccessListsDialog,
    setOpenAccessListsDialog,
  ]);

  return (
    <PoolsPageContext.Provider value={contextValue}>
      {props.children}
    </PoolsPageContext.Provider>
  );
};
