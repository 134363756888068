import { Controller, useForm } from "react-hook-form";
import { StyledDialog } from "../../../components/StyledComponents/Dialog/Dialog";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";
import {
  BodyMedium,
  H6HeadlineSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  PrimaryButton,
  SecondaryButton,
  StyledIconButton,
  StyledToggleButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { maxBy } from "lodash";
import {
  StyledFormControl,
  StyledOutlinedInput,
  StyledSelect,
} from "../../../components/StyledComponents/Inputs/Inputs.js";
import { StyledMenuItem } from "../../../components/StyledComponents/Menu/Menu.tsx";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import { useMutation } from "@tanstack/react-query";
import { usePostNewSite } from "../../queries/usePostNewSite.ts";
import { useGetSiteLists } from "../../../api/queries/useGetSiteLists.ts";
import { useAuth0 } from "@auth0/auth0-react";

const SiteOrGroupSelectionFormHeader = ({
  setOpen,
  formReset,
  setActiveStep,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      flexGrow={1}
      padding={"1.5rem 0"}
    >
      <H6HeadlineSmall>
        What would you like to add?
      </H6HeadlineSmall>

      <StyledIconButton
        onClick={() => {
          setOpen(false);
          formReset();
          setActiveStep(0);
        }}
      >
        <CloseIcon />
      </StyledIconButton>
    </Box>
  );
};

const SiteAndGroupNamingFormHeader = ({
  setOpen,
  formReset,
  siteConfig,
  setActiveStep,
}) => {
  const { whichForm } = siteConfig;
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      flexGrow={1}
      padding={"1.5rem 0"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={2}
      >
        <H6HeadlineSmall>
          {whichForm === "addSite"
            ? "Add a site"
            : "Add a group"}
        </H6HeadlineSmall>
        {whichForm === "addSite" && (
          <BodyMedium>
            Make sure the site name matches what your Q.S.E.
            calls your site. If you do not have a Q.S.E.,
            disregard.
          </BodyMedium>
        )}
        {whichForm === "addGroup" && (
          <BodyMedium>
            Enter a name and select the site you would like
            to assign this group to.
          </BodyMedium>
        )}
      </Box>

      <StyledIconButton
        onClick={() => {
          setOpen(false);
          formReset();
          setActiveStep(0);
        }}
      >
        <CloseIcon />
      </StyledIconButton>
    </Box>
  );
};
const SiteOrGroupSelectionForm = ({
  onMoveForward,
  setOpen,
  setActiveStep,
}) => {
  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        whichForm: "",
      },
    });

  const formToShow = watch("whichForm");

  const onSubmit = (data) => {
    const finalData = { ...data };
    onMoveForward(finalData);
  };
  return (
    <>
      <SiteOrGroupSelectionFormHeader
        formReset={reset}
        setOpen={setOpen}
        setActiveStep={setActiveStep}
      />
      <form onSubmit={handleSubmit(onMoveForward)}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          flexGrow={1}
          padding={0}
          gap={6}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                margin: 0,
              }}
            >
              <Controller
                name="whichForm"
                control={control}
                defaultValue={null}
                render={({
                  field: { value, onChange },
                }) => (
                  <StyledToggleButton
                    value={"addSite"}
                    onClick={() =>
                      onChange(
                        formToShow === "addSite"
                          ? null
                          : "addSite"
                      )
                    }
                    selected={formToShow === "addSite"}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    {formToShow === "addSite" ? (
                      <CheckIcon fontSize={"small"} />
                    ) : (
                      ""
                    )}{" "}
                    Site
                  </StyledToggleButton>
                )}
              />
              <Controller
                name="whichForm"
                control={control}
                defaultValue={null}
                render={({
                  field: { value, onChange },
                }) => (
                  <StyledToggleButton
                    value={"addGroup"}
                    onClick={() =>
                      onChange(
                        formToShow === "addGroup"
                          ? null
                          : "addGroup"
                      )
                    }
                    selected={formToShow === "addGroup"}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    {formToShow === "addGroup" ? (
                      <CheckIcon fontSize={"small"} />
                    ) : (
                      ""
                    )}{" "}
                    Group
                  </StyledToggleButton>
                )}
              />
            </FormControl>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            flexGrow={1}
          >
            <DialogActions>
              <PrimaryButton
                disabled={!formToShow}
                onClick={handleSubmit(onSubmit)}
              >
                Next
              </PrimaryButton>
            </DialogActions>
          </Box>
        </Box>
      </form>
    </>
  );
};

const SiteAndGroupNamingForm = ({
  onSubmit: onOverallSubmit,
  setOpen,
  dataConfig,
  setActiveStep,
  sitesAndGroupInfo,
  isPending: isAddSitesPending,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      siteSelected: "",
      groupName: "",
      siteName: "",
    },
  });
  const { whichForm } = dataConfig;
  const siteSelected = watch("siteSelected");
  const groupName = watch("groupName");
  const siteName = watch("siteName");
  const [authToken, setAuthToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = (data) => {
    onOverallSubmit(data);
  };

  React.useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAuthToken(token);
      } catch (error) {
        console.error(" Error fetching token:", error);
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  const {
    data: siteLists,
    isLoading,
    isFetched,
  } = useGetSiteLists(authToken);

  const siteNames =
    isFetched &&
    siteLists?.siteList.map((site) => site.siteName);

  return (
    <>
      <SiteAndGroupNamingFormHeader
        setOpen={setOpen}
        formReset={reset}
        siteConfig={dataConfig}
        setActiveStep={setActiveStep}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={6}
        >
          {whichForm === "addSite" ? (
            <StyledFormControl fullWidth margin="none">
              {/*<InputLabel>Status</InputLabel>*/}
              <InputLabel htmlFor="site-name">
                Name
              </InputLabel>
              <Controller
                name="siteName"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <StyledOutlinedInput
                    {...field}
                    label="Name"
                    id="site-name-input"
                    style={{
                      flex: 1,
                      margin: 0,
                      minHeight: "2.5rem",
                      // padding: "16.5px 14px",
                    }}
                  />
                )}
              />
            </StyledFormControl>
          ) : (
            <>
              {" "}
              <StyledFormControl
                fullWidth
                margin="none"
                error={!!errors.groupName}
              >
                <InputLabel htmlFor="group-name">
                  Name
                </InputLabel>
                <Controller
                  name="groupName"
                  control={control}
                  defaultValue={""}
                  rules={{
                    validate: (value) => {
                      const siteToUpdate =
                        sitesAndGroupInfo.find(
                          (site) =>
                            site.label.toLowerCase() ===
                            siteSelected.toLowerCase()
                        );
                      const groupExists =
                        siteToUpdate?.children.length > 0 &&
                        siteToUpdate?.children.findIndex(
                          (group) =>
                            group.label.toLowerCase() ===
                            value.toLowerCase()
                        ) !== -1;
                      if (groupExists) {
                        return "Group already exists";
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <StyledOutlinedInput
                      {...field}
                      label="Name"
                      id="site-name-input"
                      style={{
                        flex: 1,
                        margin: 0,
                        minHeight: "2.5rem",
                        // padding: "16.5px 14px",
                      }}
                    />
                  )}
                />
                {errors.groupName && (
                  <>
                    <FormHelperText error>
                      {" "}
                      {errors.groupName.message}{" "}
                    </FormHelperText>
                  </>
                )}
              </StyledFormControl>
              <FormControl
                fullWidth
                margin="none"
                // error={!!errors.selectedOption}
              >
                <InputLabel id="select-label">
                  Site
                </InputLabel>
                <Controller
                  name="siteSelected"
                  control={control}
                  // rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <StyledSelect
                      {...field}
                      label="Site"
                      disabled={isLoading}
                      endAdornment={
                        isLoading && (
                          <CircularProgress
                            sx={{ color: "#2D55C9" }}
                            size={20}
                          />
                        )
                      }
                    >
                      {siteNames.length > 0 &&
                        siteNames?.map((option, index) => (
                          <StyledMenuItem
                            key={index}
                            value={option}
                          >
                            {option}
                          </StyledMenuItem>
                        ))}
                    </StyledSelect>
                  )}
                />
              </FormControl>
            </>
          )}

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            flexGrow={1}
          >
            <DialogActions>
              <PrimaryButton
                disabled={isAddSitesPending}
                endIcon={
                  isAddSitesPending && (
                    <CircularProgress
                      size={"0.85rem"}
                      sx={{ color: "#2D55C9" }}
                    />
                  )
                }
                type="submit"
              >
                Submit
              </PrimaryButton>
            </DialogActions>
          </Box>
        </Box>
      </form>
    </>
  );
};

const AddSitesAndGroupsDialog = ({
  open,
  setOpen,
  sitesAndGroupInfo,
  setSiteAndGroups,
  refetch,
}) => {
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();
  const [activeStep, setActiveStep] = React.useState(0);
  const [dataConfig, setDataConfig] = useState({});
  const [authToken, setAuthToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  const totalSteps = 2;
  const onMoveForward = (data) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setDataConfig((prevDataConfig) => ({
      ...prevDataConfig,
      ...data,
    }));
  };

  const { mutate, isPending } = usePostNewSite();

  const onMoveBackward = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const generateRandomSiteId = () => {
    return (
      "site-" + Math.random().toString(36).substr(2, 9)
    );
  };

  const generateRandomNumber = (min = 50, max = 100) => {
    return (
      Math.floor(Math.random() * (max - min + 1)) + min
    );
  };

  const onSubmit = (data) => {
    if (dataConfig?.whichForm === "addSite") {
      mutate(data?.siteName);
      setActiveStep(0);
      setOpen(false);
      refetch();
    } else {
      const siteAndGroupInformation = [
        ...sitesAndGroupInfo,
      ];
      const siteToUpdateIndex =
        siteAndGroupInformation.findIndex(
          (site) =>
            site.label.toLowerCase() ===
            data.siteSelected.toLowerCase()
        );

      const siteToUpdate = siteAndGroupInformation.find(
        (site) =>
          site.label.toLowerCase() ===
          data.siteSelected.toLowerCase()
      );
      const newGroup = {
        label: data.groupName,
        id: generateRandomSiteId(),
        totalMinerCount: generateRandomNumber(),
        selectedGroup: false,
      };
      const groupToUpdate = siteToUpdate?.children;

      const updateSite = {
        ...siteToUpdate,
        children: [...groupToUpdate, newGroup],
      };

      siteAndGroupInformation[siteToUpdateIndex] =
        updateSite;

      setSiteAndGroups(siteAndGroupInformation);
      setToastMessage("Group added successfully");
      setToastOpen(true);
      setOpen(false);
      setActiveStep(0);
    }
  };
  return (
    <StyledDialog
      maxWidth={"md"}
      fullWidth
      open={open}
      hideBackdrop
    >
      <DialogContent sx={{ padding: 0 }}>
        {activeStep === 0 && (
          <SiteOrGroupSelectionForm
            onMoveForward={onMoveForward}
            setOpen={setOpen}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 1 && (
          <SiteAndGroupNamingForm
            onSubmit={onSubmit}
            setOpen={setOpen}
            dataConfig={dataConfig}
            setActiveStep={setActiveStep}
            sitesAndGroupInfo={sitesAndGroupInfo}
            isPending={isPending}
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default AddSitesAndGroupsDialog;
