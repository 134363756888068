// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Paper,
  Tooltip,
  InputBase,
  IconButton,
  ListItem,
  List,
  ListItemText,
  Checkbox,
} from "@mui/material";
import "reactjs-popup/dist/index.css";
// import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
// import usePoolsPageContext from "../../context/usePoolsPageContext.js";
import { useHistory } from "react-router-dom";
import {
  BodyLarge,
  H7TitleLarge,
} from "../components/StyledComponents/Typography/Typography.tsx";

import {
  PrimaryButton,
  SecondaryButton,
} from "../components/StyledComponents/Buttons/AuradineButtons.js";

import SearchIcon from "@mui/icons-material/Search";
import ActionDialog from "../components/ActionDialog/ActionDialog.js";
import SiteAndGroupTree from "../minerstatusV2/Components/LeftPanel/SiteAndGroupTree/SiteAndGroupTree.js";
import useAppContextProvider from "../AppContext/useAppContextProvider.js";
import { flatMap, set, stubObject } from "lodash";
import AddSitesAndGroupsDialog from "./Components/AddSitesAndGroupsDialog/AddSitesAndGroupsDialog.js";
import { StyledCheckbox } from "../components/StyledComponents/Inputs/Inputs.js";
import { useGetSiteLists } from "../api/queries/useGetSiteLists.ts";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { GetAllSitesList } from "../api/api.js";

const MUI_X_PRODUCTS = [
  {
    id: "site1",
    label: "Site 1",
    totalMinerCount: 100,
    selectedSite: false,
    children: [
      {
        id: "group1",
        label: "Group1",
        minerCount: 50,
        selectedGroup: false,
      },
      {
        id: "group2",
        label: "Group2",
        minerCount: 25,
        selectedGroup: false,
      },
      {
        id: "group3",
        label: "Group3",
        minerCount: 25,
        selectedGroup: false,
      },
    ],
  },
  {
    id: "site2",
    label: "Site 2",
    totalMinerCount: 100,
    selectedSite: false,
    children: [
      {
        id: "group1",
        label: "Group1",
        minerCount: 50,
        selectedGroup: false,
      },
      {
        id: "group2",
        label: "Group2",
        minerCount: 25,
        selectedGroup: false,
      },
      {
        id: "group3",
        label: "Group3",
        minerCount: 25,
        selectedGroup: false,
      },
    ],
  },
  {
    id: "site3",
    label: "Site 3",
    totalMinerCount: 100,
    selectedSite: false,
    children: [
      {
        id: "group1",
        label: "Group1",
        minerCount: 50,
        selectedGroup: false,
      },
      {
        id: "group2",
        label: "Group2",
        minerCount: 25,
        selectedGroup: false,
      },
      {
        id: "group3",
        label: "Group3",
        minerCount: 25,
        selectedGroup: false,
      },
    ],
  },
  {
    id: "site4",
    label: "Site 4",
    totalMinerCount: 100,
    selectedSite: false,
    children: [
      {
        id: "group2",
        label: "Group2",
        minerCount: 25,
        selectedGroup: false,
      },
      {
        id: "group3",
        label: "Group3",
        minerCount: 25,
        selectedGroup: false,
      },
    ],
  },
];

export const HeaderContainer = React.memo((props) => {
  const { data } = props;

  const history = useHistory();

  return (
    <Box
      className={"header-container"}
      display={"flex"}
      justifyContent="space-between"
      flexDirection="column"
      gap={4}
      marginBottom={"1.5rem"}
    >
      <Box
        className={"title-box"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={3.5}
      >
        <H7TitleLarge>{`Site & Group Management`}</H7TitleLarge>
        <BodyLarge>
          View, delete, or add new site and groups.
        </BodyLarge>
      </Box>
    </Box>
  );
});

const WhiteListsPageHeader = (props) => {
  const {
    onSitesAndGroupSearch,
    siteAndGroups,
    setSiteAndGroups,
    searchValue,
    setSearchValue,
    handleDeletionForSitesAndGroups,
    setOpenAddSitesAndGroupsDialog,
  } = props;

  const handleDialogOpen = () => {
    setOpenAddSitesAndGroupsDialog(true);
  };

  return (
    <Box
      display={"grid"}
      justifyContent={"space-between"}
      gridTemplateColumns={"1fr 1fr"}
    >
      <Box display={"grid"} gridTemplateColumns={"2fr 1fr"}>
        <Paper
          component="form"
          elevation={0}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#E8E7F1",
            borderRadius: "1.75rem",
            paddingLeft: "1rem",
          }}
        >
          <Tooltip
            // title={searchValue}
            disableFocusListener
            disableTouchListener
          >
            <InputBase
              sx={{ flex: 1, minWidth: "22.5rem" }}
              placeholder="Search by site or group name"
              onChange={(event) => {
                setSearchValue(event.target.value);
                onSitesAndGroupSearch(event);
              }}
              inputProps={{
                "aria-label": "search google maps",
              }}
            />
          </Tooltip>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns={"5fr 1fr 1fr"}
        justifyContent={"end"}
      >
        <Box></Box>

        <SecondaryButton
          onClick={handleDeletionForSitesAndGroups}
          disabled={
            Array.isArray(siteAndGroups) &&
            siteAndGroups.length > 0 &&
            !siteAndGroups.some(
              (siteInfo) =>
                (siteInfo.selectedSite && siteInfo.deviceCount === 0) ||
                siteInfo.groups.some(
                  (groupInfo) => groupInfo.selectedGroup && groupInfo.deviceCount === 0
                )
            )
          }
        >
          Delete
        </SecondaryButton>
        <PrimaryButton onClick={handleDialogOpen}>
          Add
        </PrimaryButton>
      </Box>
    </Box>
  );
};

/**
 * Represents the MinerPage component.
 * This component displays a table of miner devices and provides functionality to interact with them.
 */
function SitesAndGroupsManagementContent() {
  const { setToastOpen, setToastMessage } =
    useAppContextProvider();
  const { getAccessTokenSilently } = useAuth0();
  const tableContainerRef = React.useRef(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [authToken, setAuthToken] = useState("");
  const [deletionDialogOpen, setDeletionDialogOpen] =
    useState(false);
  const [
    openAddSitesAndGroupsDialog,
    setOpenAddSitesAndGroupsDialog,
  ] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [siteAndGroups, setSiteAndGroups] = React.useState(
    []
  );

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAuthToken(token);
      } catch (error) {
        console.error(" Error fetching token:", error);
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  const {
    data: siteLists,
    isLoading: isSiteGroupDataLoading,
    isFetching: isSiteGroupDataFetching,
    isFetched: isSiteGroupDataFetched,
    refetch,
  } = useGetSiteLists(authToken, {
    notifyOnChangeProps: "all",
  });



  useEffect(() => {
    if (siteLists) {
      if (
        siteLists?.status === "ok" &&
        siteLists?.siteList?.length > 0
      ) {
        const siteList = siteLists?.siteList.map((site) => {
          return {
            ...site,
            selectedSite: false,
            groups: site.groups.map((group) => {
              return {
                ...group,
                selectedGroup: false,
              };
            }),
          };
        });
        setSiteAndGroups(siteList);
      }
    }
  }, [siteLists, isSiteGroupDataFetching]);

  const [numberOfSelections, setNumberOfSelections] =
    useState(0);

  const onSitesAndGroupSearch = (e) => {
    if (e.target.value === "") {
      setSiteAndGroups(siteLists?.siteList);
      return;
    } else {
      const sitesData = [...siteAndGroups];
      const foundSite = sitesData.filter((site) => {
        return (
          site?.siteName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          (site.groups &&
            site.groups.some((group) =>
              group.DGName.toLowerCase().includes(
                e.target.value.toLowerCase()
              )
            ))
        );
      });

      setSiteAndGroups(foundSite);
    }
  };

  const onDeletionClick = () => {
    setDeletionDialogOpen(true);
  };

  const handleDeletion = () => {
    const siteAndGroupInfoCopy = [...siteAndGroups];

    const filteredData = siteAndGroupInfoCopy.map(site => {
      if (site.selectedSite) {
        return site
      }
      if (site.groups.length > 0 && !site.selectedSite && site.groups.some(group => group.selectedGroup)) {
        const selectedGroups = site.groups.filter(group => group.selectedGroup)
        return { ...site, groups: selectedGroups }
      }
      return null;
    }).filter(site => site !== null)
    console.log("fiteredDataAfterDeletion", filteredData);
    setDeletionDialogOpen(false);
  };

  const DeletionList = () => {
    const [deletionList, setDeletionList] = useState([]);
    useEffect(() => {
      const deletionList = flatMap(
        siteAndGroups,
        (siteGroupInfo) => {
          if (siteGroupInfo.selectedSite) {
            return siteGroupInfo.groups.length > 0
              ? siteGroupInfo.groups.map(
                (groupInfo) =>
                  `/${siteGroupInfo.siteName}/${groupInfo.DGName}`
              )
              : `/${siteGroupInfo.siteName}/`;
          } else {
            return siteGroupInfo.groups
              .filter(
                (groupInfo) => groupInfo.selectedGroup
              )
              .map(
                (groupInfo) =>
                  `/${siteGroupInfo.siteName}/${groupInfo.DGName}`
              );
          }
        }
      ).filter(Boolean); // Filter out empty strings
      setDeletionList(deletionList);
    }, [siteAndGroups]);

    return (
      <List sx={{ maxHeight: "12rem", overflowY: "auto" }}>
        {deletionList.map((itemToBeDeleted, index) => (
          <ListItem button={false} key={index} sx={{ borderBottom: "1px solid #C4C5D6" }}>
            <ListItemText>
              <BodyLarge>{itemToBeDeleted}</BodyLarge>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };

  const handleParentCheckboxSelection = (event) => {
    if (event.target.checked) {
      const siteGroupsCopy = [...siteAndGroups];
      setNumberOfSelections(0);
      const siteGroupsCopyAllTrue = siteGroupsCopy.map(
        (siteAndGroup) => {
          let selectionCount = 0;
          siteAndGroup.selectedSite = true;
          selectionCount += 1;
          if (siteAndGroup.groups.length > 0) {
            siteAndGroup.groups = [
              ...siteAndGroup.groups,
            ].map((group) => {
              group.selectedGroup = true;
              selectionCount += 1;
              return group;
            });
          }
          setNumberOfSelections(
            (prev) => prev + selectionCount
          );
          return siteAndGroup;
        }
      );

      setSiteAndGroups(siteGroupsCopyAllTrue);
    } else {
      const siteGroupsCopy = [...siteAndGroups];
      const siteGroupsCopyAllFalse = siteAndGroups.map(
        (siteAndGroup) => {
          siteAndGroup.selectedSite = false;
          setNumberOfSelections((prev) => prev - 1);
          if (siteAndGroup.groups.length > 0) {
            siteAndGroup.groups = [
              ...siteAndGroup.groups,
            ].map((group) => {
              group.selectedGroup = false;
              setNumberOfSelections((prev) => prev - 1);
              return group;
            });
          }
          return siteAndGroup;
        }
      );
      setSiteAndGroups(siteGroupsCopyAllFalse);
    }
  };

  return (
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        // overflowY: "auto",
        height: "100vh",
      }}
    >
      <HeaderContainer />
      <Grid
        container
        className={"miner-page-table-container"}
        display={"flex"}
        flexDirection={"row"}
        spacing={4}
        sx={{ transition: "all 0.3s ease-in-out" }}
      >
        <Grid
          ref={tableContainerRef}
          item
          xs={12}
          sx={{
            transition: "width 0.3s ease-in-out", // Smooth transition for width
          }}
        >
          <Box
            className={"miner-page-table"}
            display={"flex"}
            flexDirection={"row"}
            backgroundColor={"#F3F2FD"}
            padding={"0.75rem 0  0.75rem 0"}
            borderRadius={"1rem"}
            sx={{
              transition: "width 0.3s ease-in-out", // Smooth transition for width
            }}
          >
            <Box
              className={"table-container"}
              display={"flex"}
              flexDirection={"column"}
              padding={"0 0.75rem 0 0.75rem"}
              height={"auto"}
              minWidth={"0"}
              gap={4}
              flex={3}
              flexShrink={1}
            >
              <WhiteListsPageHeader
                onSitesAndGroupSearch={
                  onSitesAndGroupSearch
                }
                siteAndGroups={siteAndGroups}
                setSiteAndGroups={setSiteAndGroups}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleDeletionForSitesAndGroups={
                  onDeletionClick
                }
                setOpenAddSitesAndGroupsDialog={
                  setOpenAddSitesAndGroupsDialog
                }
              />
              <Box
                className={"Datagrid-container"}
                display={"flex"}
                flexDirection={"column"}
                flexShrink={1}
                flexGrow={1}
                // overflowY={"auto"}
                height="calc(100vh - 17rem)"
              >
                {siteAndGroups.length > 0 && (
                  <Box
                    display="grid"
                    gridTemplateColumns={"2rem auto"}
                    justifySelf={"start"}
                    alignItems={"center"}
                    columnGap={2}
                    marginLeft={"1.30rem"}
                  >
                    <StyledCheckbox
                      onChange={(e) =>
                        handleParentCheckboxSelection(e)
                      }
                      indeterminate={
                        numberOfSelections > 0 &&
                        numberOfSelections <
                        siteAndGroups.length +
                        siteAndGroups.reduce(
                          (acc, site) =>
                            acc + site.groups.length,
                          0
                        )
                      }
                      checked={
                        numberOfSelections !== 0 &&
                        numberOfSelections ===
                        siteAndGroups.length +
                        siteAndGroups.reduce(
                          (acc, site) =>
                            acc + site.groups.length,
                          0
                        )
                      }
                    />
                    {`${numberOfSelections} items selected`}
                  </Box>
                )}
                <SiteAndGroupTree
                  siteAndGroups={siteAndGroups}
                  setSiteAndGroups={setSiteAndGroups}
                  onSitesAndGroupSearch={
                    onSitesAndGroupSearch
                  }
                  clickDisabled={true}
                  multiSelectModel={true}
                  maxHeight={"calc(100vh - 19rem)"}
                  setNumberOfSelections={
                    setNumberOfSelections
                  }
                  numberOfSelections={numberOfSelections}
                  isSiteGroupDataLoading={isSiteGroupDataLoading}
                  isSiteGroupDataFetching={isSiteGroupDataFetching}
                  isSiteGroupDataFetched={isSiteGroupDataFetched}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <AddSitesAndGroupsDialog
        open={openAddSitesAndGroupsDialog}
        setOpen={setOpenAddSitesAndGroupsDialog}
        sitesAndGroupInfo={siteAndGroups}
        setSiteAndGroups={setSiteAndGroups}
        refetch={refetch}
      />
      <ActionDialog
        open={deletionDialogOpen}
        onClose={() => setDeletionDialogOpen(false)}
        headerMessage={"Delete these sites or groups?"}
        subMessage={
          "Deleting the site or group removes it from FluxVision. This action cannot be undone."
        }
        buttonText={"Delete"}
        deleteAction={handleDeletion}
        extraInfo={true}
        ExtraInfoComponent={DeletionList}
      />
    </Container>
  );
}

export default function SitesAndGroupsManagementPage() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
      },
      mutations: {
        retry: 0,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <SitesAndGroupsManagementContent />
    </QueryClientProvider>
  );
}
