import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
import "reactjs-popup/dist/index.css";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Avatar from "./Avatar/Avatar";
import FluxVisionLogo from "../../assets/fluxvision_logo.svg";
import { useHistory, useLocation } from "react-router-dom";
import IndicatorIcon from "../../assets/Indicator.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";

import MenuItem from "@mui/material/MenuItem";

import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import {
  StyledMenu,
  StyledMenuItem,
} from "../StyledComponents/Menu/Menu.tsx";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { StyledIconButton } from "../StyledComponents/Buttons/AuradineButtons";
import Navigationcontextprovider from "./context/Navigationcontextprovider.js";
import useNavigationContext from "./context/useNavigationContext";

const UnselectedRailingItemText = styled(Typography)({
  color: "#444653",
  fontSize: "14px",
  fontStyle: "normal",
  fontFamily: "Roboto",
  fontWeight: 500,
  lineHeight: "20px",
});

const SelectedRailingItemText = styled(Typography)({
  color: "#2D55C9",
  fontSize: "14px",
  fontStyle: "normal",
  fontFamily: "Roboto",
  fontWeight: 500,
  lineHeight: "20px",
});

const NavigationRailingStyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    borderRadius: "1rem",
    marginTop: "0.80%",
    padding: "0.75rem",
    minWidth: "21rem",
    backgroundColor: "#F3F2FD",
  },
});

const NavigationRailingStyledMenuItem = styled(MenuItem)({
  padding: "1rem 1.5rem",
  color: "#444653",
  /* material-theme/label/large */
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "1.25rem" /* 142.857% */,
  letterSpacing: "0.00625rem",
  backgroundColor: "transparent",
  "&:hover": {
    borderRadius: "6.25rem",
    backgroundColor: "rgba(29, 27, 32, 0.08)",
  },
  "&.Mui-focusVisible": {
    outline: "none",
    borderRadius: "6.25rem",
    backgroundColor: "rgba(29, 27, 32, 0.08)",
  },
  "&.Mui-selected": {
    borderRadius: "6.25rem",
    backgroundColor: "#C7D1FF",
  },
});

const NavigationRailingItemText = React.memo((props) => {
  const {
    setSelectedRouteIndex,
    currentIndex,
    route,
    selectedRouteIndex,
  } = props;
  return (
    <>
      {selectedRouteIndex === currentIndex ? (
        <>
          <SelectedRailingItemText>
            {route.name}
          </SelectedRailingItemText>
        </>
      ) : (
        <>
          <UnselectedRailingItemText>
            {route.name}
          </UnselectedRailingItemText>
        </>
      )}
    </>
  );
});

const NavigationRailingItem = (props) => {
  const {
    route,
    selectedRouteIndex,
    setSelectedRouteIndex,
    currentIndex,
    setMenuSelectedValue,
    menuSelectedValue,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const shouldOpenMenu = useMemo(
    () => Boolean(anchorEl),
    [anchorEl]
  );
  const onRailingTextSelect = useCallback(
    (event) => {
      if (
        selectedRouteIndex !== currentIndex &&
        event.type === "click"
      ) {
        setSelectedRouteIndex(currentIndex);
        setMenuSelectedValue(null);

        history.push(route.navigateTo);
      }
    },
    [
      currentIndex,
      history,
      route.navigateTo,
      selectedRouteIndex,
    ]
  );
  const onMenuItemSelect = useCallback(
    (event, index, route) => {
      event.stopPropagation();

      if (event.type === "click") {
        setSelectedRouteIndex(currentIndex);
        setMenuSelectedValue((prev) => {
          if (prev !== index) return index;
          return prev;
        });
        history.push(route);
        handleClose();
      }
    },
    [currentIndex, history, setSelectedRouteIndex]
  );

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // two types of navigationRailingItemText -> one is when there is childrenRoutes and one is when there is no childrenRoutes
  // if there are childrenRoutes, then the navigationRailingItemText will be a dropdown
  // if there are no childrenRoutes, then the navigationRailingItemText will be a simple text
  // on click should navigate when there is no childrenRoutes
  // on click should open the dropdown when there are childrenRoutes and on click of the dropdown item, should navigate to the respective route and still mark the parent.
  return (
    <>
      {!route.hasChildrenRoutes ? (
        <Box
          display={"flex"}
          className={"navigation-railing-item-text"}
          flexDirection={"column"}
          justifyContent={
            selectedRouteIndex === currentIndex
              ? "flex-end"
              : "center"
          }
          alignItems={"center"}
          height={"100%"}
          sx={{ cursor: "pointer" }}
          onClick={(event) => onRailingTextSelect(event)}
        >
          <Box
            className="navigation-railing-item-text"
            display={"flex"}
            padding={"0 1rem "}
          >
            <NavigationRailingItemText {...props} />
          </Box>
          {selectedRouteIndex === currentIndex ? (
            <img src={IndicatorIcon} />
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          className={"navigation-railing-item-menu"}
          justifyContent={
            selectedRouteIndex === currentIndex
              ? "flex-end"
              : "center"
          }
          alignItems={
            selectedRouteIndex === currentIndex
              ? "flex-start"
              : "center"
          }
          flexShrink={0}
          sx={{ cursor: "pointer" }}
        >
          <Box
            className={`navigation-railing-item-menu${currentIndex}`}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={handleMenuOpen}
          >
            <Box
              className="navigation-railing-item-text"
              display={"flex"}
              padding={"0 1rem"}
            >
              <NavigationRailingItemText
                className={`navigation-railing-item-text${currentIndex}`}
                {...props}
              />
            </Box>
            {!Boolean(anchorEl) ? (
              <ArrowDropDownRoundedIcon
                fontSize={"small"}
              />
            ) : (
              <ArrowDropUpRoundedIcon fontSize={"small"} />
            )}
          </Box>
          {selectedRouteIndex === currentIndex ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              ml={selectedRouteIndex === 2 ? "25%" : "15%"}
            >
              {" "}
              <img src={IndicatorIcon} />{" "}
            </Box>
          ) : (
            <></>
          )}
          <NavigationRailingStyledMenu
            defaultValue={menuSelectedValue}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={shouldOpenMenu}
            onClose={handleClose}
          >
            {route.childrenRoutes.map(
              (childRoute, index) => {
                return (
                  <NavigationRailingStyledMenuItem
                    key={index}
                    selected={
                      menuSelectedValue === index &&
                      childRoute.navigateTo === currentPath
                    }
                    onClick={(event) =>
                      onMenuItemSelect(
                        event,
                        index,
                        childRoute.navigateTo
                      )
                    }
                  >
                    {" "}
                    {childRoute.name}
                  </NavigationRailingStyledMenuItem>
                );
              }
            )}
          </NavigationRailingStyledMenu>
        </Box>
      )}
    </>
  );
};

const NavigationRailing = (props) => {
  const { routes } = props;
  const location = useHistory();
  const { pathname } = useLocation();
  const {
    menuSelectedValue,
    setMenuSelectedValue,
    selectedRouteIndex,
    setSelectedRouteIndex,
  } = useNavigationContext();

  useEffect(() => {
    const currentRoute = routes.find((route) =>
      !route?.hasChildrenRoutes
        ? route?.navigateTo === pathname
        : route?.childrenRoutes.some(
            (childRoute) =>
              childRoute?.navigateTo === pathname
          )
    );

    if (currentRoute?.hasChildrenRoutes) {
      const currentChildRoute =
        currentRoute.childrenRoutes.findIndex(
          (childRoute) => childRoute.navigateTo === pathname
        );
      if (currentChildRoute !== -1) {
        setMenuSelectedValue(currentChildRoute);
      }
    }

    const currentRouteIndex = routes.indexOf(currentRoute);

    if (currentRouteIndex !== -1) {
      setSelectedRouteIndex(currentRouteIndex);
    }
    if (currentRouteIndex === -1) {
      setSelectedRouteIndex(-1);
    }
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={2}
      height={"100%"}
      className={"navigation-railing"}
    >
      {routes.map((route, index) => {
        return (
          <NavigationRailingItem
            key={index}
            currentIndex={index}
            route={route}
            selectedRouteIndex={selectedRouteIndex}
            setSelectedRouteIndex={setSelectedRouteIndex}
            menuSelectedValue={menuSelectedValue}
            setMenuSelectedValue={setMenuSelectedValue}
          />
        );
      })}
    </Box>
  );
};

const NavBarLeftContainer = () => {
  const routes = [
    {
      name: "Home",
      value: "home",
      navigateTo: "/",
      hasChildrenRoutes: false,
    },
    {
      name: "Miner",
      value: "miner",
      hasChildrenRoutes: true,
      childrenRoutes: [
        {
          name: "Miner Inventory",
          value: "minersandgroups",
          navigateTo: "/minerstatus",
        },
        {
          name: "Site and Group Management",
          value: "siteandgroup",
          navigateTo: "/sitegroupmanagement",
        },
      ],
    },
    {
      name: "Configuration",
      value: "configuration",
      hasChildrenRoutes: true,
      childrenRoutes: [
        {
          name: "Pools",
          value: "pools",
          navigateTo: "/pools",
        },
        {
          name: "Price Profile",
          value: "priceprofile",
          navigateTo: "/priceConfig",
        },
        {
          name: "Site Demand",
          value: "siteDemand",
          navigateTo: "/demandResponse",
        },
      ],
    },
  ];
  return (
    <Box
      display={"flex"}
      flexGrow={1}
      flexDirection={"row"}
      height={"48px !important"}
      gap={8}
      alignItems={"center"}
    >
      <img
        src={FluxVisionLogo}
        height={"24px"}
        alt={"auradine_fluxvision_logo"}
      />
      <NavigationRailing routes={routes} />
    </Box>
  );
};

const HelpMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { setSelectedRouteIndex, setMenuSelectedValue } =
    useNavigationContext();
  async function downloadPdf(pdfName) {
    try {
      let response = {};

      response = await fetch(`./${pdfName}`);

      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");

      // Set the link's href to the Blob URL
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with the desired file name
      link.download = pdfName;

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  }

  const handlePDFDownload = () => {
    // Replace these URLs with the actual URLs of your PDFs

    const pdfNames = [
      "(BETA) Teraflux API Reference.pdf",
      "(BETA) Teraflux AT1500 & AI2500 Hardware Reference.pdf",
      "(BETA) Teraflux Miner Quick Start & Web Interface Reference.pdf",
    ];

    // Download each PDF
    pdfNames.forEach((name) => {
      downloadPdf(name);
    });
  };
  const navigateToFluxGPT = () => {
    history.push("/fluxgpt");
    setMenuSelectedValue(null);
    setSelectedRouteIndex(-1);
    handleClose();
  };
  return (
    <>
      <StyledIconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <HelpOutlineRoundedIcon fontSize={"medium"} />
      </StyledIconButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <StyledMenuItem onClick={navigateToFluxGPT}>
          FluxGPT
        </StyledMenuItem>
        <StyledMenuItem onClick={handlePDFDownload}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={3}
          >
            Documentation{" "}
            <ListItemIcon>
              <LaunchOutlinedIcon />
            </ListItemIcon>
          </Box>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const { setSelectedRouteIndex, setMenuSelectedValue } =
    useNavigationContext();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToAdministrators = () => {
    setMenuSelectedValue(null);
    setSelectedRouteIndex(-1);
    history.push("/organization");
    handleClose();
  };

  const navigateToJobs = () => {
    setMenuSelectedValue(null);
    setSelectedRouteIndex(-1);
    history.push("/job");
    handleClose();
  };

  return (
    <>
      <StyledIconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SettingsRoundedIcon fontSize={"medium"} />
      </StyledIconButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <StyledMenuItem onClick={navigateToAdministrators}>
          Administrators{" "}
        </StyledMenuItem>
        <StyledMenuItem onClick={navigateToJobs}>
          Jobs
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

const NavBarRightContainer = ({ isAuthenticated }) => {
  const rightContainer = () => {
    return (
      <Box display={"flex"} flexDirection={"row"} gap={4}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={2.5}
        >
          <HelpMenu />
          <SettingsMenu />
        </Box>
        {isAuthenticated ? <Avatar /> : <></>}
      </Box>
    );
  };
  return rightContainer();
};
const TopNavigation = () => {
  const { isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  return (
    <Navigationcontextprovider>
      <Box
        flexGrow={1}
        sx={{
          backgroundColor: "#EEEDF7",
          maxHeight: "48px",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <NavBarLeftContainer />
          <NavBarRightContainer
            isAuthenticated={isAuthenticated}
          />
        </Toolbar>
      </Box>
    </Navigationcontextprovider>
  );
};

TopNavigation.propTypes = {
  isMobileSidebarOpen: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default TopNavigation;
