import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { AddNewSite } from "../../api/api"; // Adjust the import path as necessary
import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useAppContextProvider from "../../AppContext/useAppContextProvider";

type SiteResult = {
  status: string;
  Msg?: string;
  errorCode?: string;
};

export const usePostNewSite = (): UseMutationResult<
  SiteResult,
  Error
> => {
  const queryClient = useQueryClient();
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();
  const { getAccessTokenSilently, isAuthenticated } =
    useAuth0();
  const [authToken, setAuthToken] = useState<string | null>(
    null
  );

  const mutation = useMutation({
    mutationFn: ({
      siteName,
      token,
    }: {
      siteName: string;
      token: string;
    }) => AddNewSite({ siteName }, token),
    onSuccess: async (data) => {
      if (data.status === "error") {
        setToastMessage(
          `Error code: ${data.errorCode}, Error msg: ${data.Msg}` ||
            "Error adding site"
        );
        setToastOpen(true);
      } else {
        console.log("Site added successfully");
        console.log(
          "Refetching site lists",
          queryClient.getQueryCache().getAll()
        );
        await queryClient.invalidateQueries({
          queryKey: ["getSiteLists", authToken],
          refetchType: "all",
        });
        // await queryClient.refetchQueries({
        //   queryKey: ["getSiteLists"],
        // });
        setToastMessage("Site added successfully");
        setToastOpen(true);
      }
    },
    onError: (error) => {
      console.error("Error adding site:", error);
      setToastMessage("Error adding site");
      setToastOpen(true);
    },
  });

  const mutateWithToken = useCallback(
    async (siteName: string) => {
      if (!authToken || !isAuthenticated) {
        const token = await getAccessTokenSilently();
        setAuthToken(token);
        mutation.mutate({ siteName, token });
      } else {
        mutation.mutate({ siteName, token: authToken });
      }
    },
    [authToken, getAccessTokenSilently, mutation]
  );

  return { ...mutation, mutate: mutateWithToken };
};
