import {
  StyledDialog,
  StyledDialogTitle,
} from "../../../components/StyledComponents/Dialog/Dialog";
import {
  BodyMedium,
  H6HeadlineSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import { useForm } from "react-hook-form";
import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons";
import {
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import MenuItem from "@mui/material/MenuItem";
import { PostUser } from "../../../api/api.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider";

const EditProfileDialog = ({
  user,
  open,
  onClose,
  refetch,
}) => {
  const queryClient = useQueryClient();
  const { setToastMessage, setToastOpen, authToken } =
    useAppContextProvider();
  const mutation = useMutation({
    mutationFn: ({ token, user }) => PostUser(token, user),
    onSuccess: () => {
      setToastOpen(true);
      setToastMessage("User information updated");
      queryClient.invalidateQueries({
        queryKey: ["user"],
        refetchType: "active",
      });
      refetch();
    },
    onError: () => {
      setToastOpen(true);
      setToastMessage("Failed to update user information");
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: user?.name || "",
      address: user?.address || "",
      phone: user?.phone || "",
      email: user?.email || "",
      role: user?.role || "",
    },
  });
  React.useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        address: user.address,
        phone: user.phone,
        email: user.email,
        role: user.role,
      });
    }
  }, [user, reset]);
  const handleFormSubmit = (data) => {
    console.log("data", data);
    mutation.mutate({ token: authToken, user: data });
    onClose();
  };
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      sx={{ overflowX: "hidden" }}
    >
      <StyledDialogTitle>
        <H6HeadlineSmall style={{ textAlign: "left" }}>
          Edit profile
        </H6HeadlineSmall>
        <BodyMedium style={{ textAlign: "left" }}>
          Update your information to keep your details
          current.
        </BodyMedium>
      </StyledDialogTitle>
      <DialogContent
        sx={{ padding: 0, overflowX: "hidden" }}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={6}
            marginTop={1}
          >
            <TextField
              {...register("name")}
              label="Name"
              variant="outlined"
              fullWidth
              minHeight={"3.5rem"}
              margin={"none"}
              defaultValue={user?.name}
              error={errors?.name}
              helperText={errors.name?.message}
            />

            <TextField
              {...register("address")}
              label="Address"
              variant="outlined"
              fullWidth
              margin={"none"}
              minHeight={"3.5rem"}
              defaultValue={user?.address}
              error={errors?.address}
              helperText={errors.address?.message}
            />

            <TextField
              {...register("phone")}
              label="Phone number"
              variant="outlined"
              fullWidth
              margin={"none"}
              minHeight={"3.5rem"}
              defaultValue={user?.phone}
              error={errors?.phone}
              helperText={errors?.phone?.message}
            />

            <TextField
              {...register("email")}
              label="Email"
              variant="outlined"
              fullWidth
              margin={"none"}
              minHeight={"3.5rem"}
              defaultValue={user?.email}
              error={errors?.email}
              helperText={errors?.email?.message}
              InputProps={{
                endAdornment: user?.invite ===
                  "accepted" && (
                  <InputAdornment position="end">
                    <span
                      className="material-symbols-outlined"
                      style={{ color: "#24A64A" }}
                    >
                      check_circle
                    </span>
                  </InputAdornment>
                ),
              }}
              disabled
            />

            <FormControl
              fullWidth
              variant="outlined"
              margin="none"
            >
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                label="Role"
                defaultValue={user?.role}
                {...register("role")}
                error={errors?.role}
                disabled={user?.role === "readonly"}
              >
                <MenuItem
                  value="superadmin"
                  disabled={
                    user?.role === "superadmin" ||
                    user?.role === "pooladmin"
                  }
                >
                  Super admin
                </MenuItem>
                <MenuItem
                  value="pooladmin"
                  disabled={user?.role === "pooladmin"}
                >
                  Pool admin
                </MenuItem>
                <MenuItem value="readonly">
                  Read only
                </MenuItem>
              </Select>
              {errors.role && (
                <p style={{ color: "red" }}>
                  {errors.role.message}
                </p>
              )}
            </FormControl>

            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={4}
              justifyContent={"flex-end"}
            >
              <SecondaryButton onClick={onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton type="submit">
                Save
              </PrimaryButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};

export default EditProfileDialog;
