import React from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";

export const StyledOutlinedInput = styled(OutlinedInput)({
  " &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      borderColor: " #2D55C9",
    },
});

export const StyledTextField = styled(TextField)(
  ({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000", // Default border color
      }, // Default border color
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2D55C9", // Change border color when focused
      },
    },
    "& .MuiInputLabel-root": {
      "&.Mui-focused": {
        color: "#2D55C9", // Change label color when focused
      },
    },
  })
);

export const StyledDateTimePicker = styled(TextField)({
  "& .MuiOutlinedInput-root": {},
});

export const StyledInputLabel = styled(InputLabel)({
  "&.Mui-focused": {
    color: "#2D55C9",
  },
});

export const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000", // Default border color when not focused
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#2D55C9", // Change border color when focused
  },
});

export const StyledFormControl = styled(FormControl)(
  ({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2D55C9", // Custom focused color
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000", // Default border color
      },

      "& .MuiInputLabel-root.Mui-focused": {
        color: "#2D55C9", // Change label color when focused
      },
    },
  })
);

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F3f2fd",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
  borderRadius: "0.5rem",
}));

export const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-indeterminate": {
    color: "#2D55C9", // Change this to your desired color
  },
  "&.Mui-checked": {
    color: "#2D55C9", // Change this to your desired color
  },
});
