import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as EmailValidator from "email-validator";
import * as api from "../api/api";

import CloseIcon from "@mui/icons-material/Close";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  Box,
  capitalize,
  FormControl,
  Select,
} from "@mui/material";
import Styled from "@emotion/styled";
import {
  StyledDialog,
  StyledDialogTitle,
} from "../components/StyledComponents/Dialog/Dialog";
import {
  PrimaryButton,
  SecondaryButton,
} from "../components/StyledComponents/Buttons/AuradineButtons";
import {
  BodyMedium,
  H6HeadlineSmall,
} from "../components/StyledComponents/Typography/Typography.tsx";
import {
  StyledMenu,
  StyledMenuItem,
} from "../components/StyledComponents/Menu/Menu.tsx";
import { StyledInputLabel } from "../components/StyledComponents/Inputs/Inputs.js";

const SuccessModal = ({ isOpen, onClose, Status, Msg }) => {
  console.log("status is", Status);
  let h4Msg = "";
  let pMsg = "";
  switch (Status) {
    case "ok":
      h4Msg = "Success!";
      pMsg = Msg;
      break;
    case "error":
      h4Msg = "Failed!";
      pMsg = Msg;
      break;
    case "unauth":
      h4Msg = "Authorization Failed!";
      pMsg = Msg;
      break;
    default:
      h4Msg = "Unknown Error";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Aurascape";
      break;
  }
  return (
    <Popup
      className="popup1-content"
      open={isOpen}
      onClose={onClose}
    >
      <h4 className="popup-title">{h4Msg}</h4>
      <p className="success-message">{pMsg}</p>
      <div className="button-container">
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

export default function InviteDialog({ setState }) {
  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [error, setError] = React.useState({
    error: false,
    helperText: "",
  });
  const [errorRole, setErrorRole] = React.useState({
    error: false,
    helperText: "",
  });

  const [errorName, setErrorName] = React.useState({
    error: false,
    helperText: "",
  });

  let roles = ["pooladmin", "admin", "readonly"];
  const [submitMsg, setSubmitMsg] = React.useState("");
  const [Msg, setMsg] = React.useState("");
  const [successModalOpen, setSuccessModalOpen] =
    React.useState(false);
  const [nameFilled, setNameFilled] = React.useState(false);
  const [emailFilled, setEmailFilled] =
    React.useState(false);
  const [roleFilled, setRoleFilled] = React.useState(false);

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9._@]+$/;
    return pattern.test(email);
  }

  const checkEmail = (value) => {
    const valid = isValidEmail(value);
    if (valid) {
      const valid1 = EmailValidator.validate(value);
      if (!valid1) {
        setError({
          error: true,
          helperText: "Invalid email",
        });
      } else {
        setError({ error: false, helperText: "" });
      }
      return valid1;
    } else {
      setError({
        error: true,
        helperText: "Invalid email",
      });
    }
    return valid;
  };

  const checkRole = (value) => {
    if (value == "true" || value == "false") {
      setErrorRole({ error: false, helperText: "" });
      return true;
    } else {
      setErrorRole({
        error: true,
        helperText:
          "Invalid role value (allowed true or false)",
      });
      return false;
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.value;
    // console.log(value)
    checkEmail(value);
    setEmail(value);
    setEmailFilled(value.trim() !== "");
  };

  const handleInputChangeName = (event) => {
    const target = event.target;
    const value = target.value;
    setName(value);
    // Check if the input is not empty
    setNameFilled(value.trim() !== "");
  };

  const handleInputChangeRole = (event) => {
    const target = event.target;
    const value = target.value;
    const inputValue = event.target.value.toLowerCase();
    // checkRole(inputValue);
    setRole(inputValue);
    setRoleFilled(true);
  };

  const handleInputChangePhone = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.value;

    //console.log(value)
    setPhone(value);
  };

  const handleInputChangeAddress = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.value;

    // console.log(value)
    setAddress(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitHandler = async (event) => {
    if (error.error) return;

    const fn = async () => {
      var localrole = role.toLowerCase();
      if (role.toLowerCase() === "readonly") {
        localrole = "user";
      }
      const token = await getAccessTokenSilently();
      let data = await api.PostInvite(token, {
        email: email,
        name: name,
        role: localrole,
        phone: phone,
        address: address,
      });
      if (data.status === "ok") {
        setMsg(data.msg);
        setSubmitMsg("ok");
        setSuccessModalOpen(true);
      } else {
        if (
          data.hasOwnProperty("errorCode") &&
          data.errorCode === 1009
        ) {
          setMsg(data.msg);
          setSubmitMsg("unauth");
          setSuccessModalOpen(true);
        } else {
          setMsg(data.msg);
          setSubmitMsg("error");
          setSuccessModalOpen(true);
        }
      }
      setState((prevState) => !prevState);
      setOpen(false);
    };
    fn();
  };

  return (
    <div>
      <PrimaryButton
        variant="outlined"
        onClick={handleClickOpen}
      >
        {" "}
        Invite
      </PrimaryButton>
      <StyledDialog
        maxWidth="sm"
        sx={{ overflow: "hidden" }}
        open={open}
        onClose={handleClose}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          paddingTop={"1rem"}
          paddingBottom={"1rem"}
          gap={2}
        >
          <H6HeadlineSmall style={{ textAlign: "left" }}>
            Invite user
          </H6HeadlineSmall>
          <BodyMedium style={{ textAlign: "left" }}>
            {" "}
            You’re adding a new team member to FluxVision.
            After the invitation is sent, they will receive
            an email to set up their account.
          </BodyMedium>
        </Box>

        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            flexShrink={0}
            gap={4}
          >
            <TextField
              autoFocus
              name="name"
              label="Name"
              type="name"
              size={"medium"}
              value={name}
              fullWidth
              variant="outlined"
              onChange={handleInputChangeName}
              error={errorName.error}
              helperText={errorName.helperText}
            />
            <TextField
              autoFocus
              name="address"
              size={"medium"}
              label="Address"
              type="address"
              value={address}
              fullWidth
              variant="outlined"
              onChange={handleInputChangeAddress}
            />
            <FormControl fullWidth>
              <StyledInputLabel>Role</StyledInputLabel>
              <Select
                value={role}
                label="Role"
                fullWidth
                onChange={handleInputChangeRole}
                error={errorRole.error}
              >
                {roles.map((role) => (
                  <StyledMenuItem value={role}>
                    {capitalize(role)}
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              name="phone"
              label="Phone number "
              size={"medium"}
              type="phone"
              value={phone}
              fullWidth
              variant="outlined"
              onChange={handleInputChangePhone}
            />
            <TextField
              autoFocus
              size={"medium"}
              name="email"
              label="Email address"
              type="email"
              value={email}
              fullWidth
              variant="outlined"
              onChange={handleInputChange}
              helperText={error.helperText}
              error={error.error}
            />
          </Box>
        </>
        <DialogActions>
          <SecondaryButton
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={submitHandler}
            disabled={
              !nameFilled || !emailFilled || !roleFilled
            }
          >
            Save
          </PrimaryButton>
        </DialogActions>
      </StyledDialog>
      <div>
        <SuccessModal
          isOpen={successModalOpen}
          onClose={handleCloseSuccessModal}
          Status={submitMsg}
          Msg={Msg}
        />
      </div>
    </div>
  );
}
