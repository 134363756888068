import {
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { GetAllSitesList } from "../api";

type GetSiteListsProps = {
  authToken: string;
};

type Group = {
  groupName: string;
  deviceCount: number | string;
};

type Site = {
  siteName: string;
  groups: Group[];
};

type SiteList = {
  status: string;
  siteList: Site[];
};

export const useGetSiteLists = (
  authToken: GetSiteListsProps
): UseQueryResult<SiteList, Error> => {
  return useQuery({
    queryKey: ["getSiteLists", authToken],
    queryFn: ({ queryKey }) => GetAllSitesList(authToken),
    enabled: !!authToken,
    staleTime: 0,
    notifyOnChangeProps: "all",
  });
};
