import React from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import {
  BodyLarge,
  H7TitleLarge,
  H9TitleSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledIconButton } from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import Styled from "@emotion/styled";
import usePoolsPageContext from "../../context/usePoolsPageContext.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import ActionDialog from "../../../components/ActionDialog/ActionDialog.js";

export const StyledMenu = Styled(Menu)({
  "& .MuiList-root": {
    backgroundColor: "#eeedf7",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    "& .MuiPaper-root": {
      boxShadow:
        "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
    },
  },
});

export const StyledMenuItem = Styled(MenuItem)({
  padding: "0.5rem 0.75rem",
  minWidth: "12.5rem",
  backgroundColor: "#eeedf7",
  "&:hover": {
    backgroundColor: "#E2E1EC",
  },
});

export const PoolCardMenu = (props) => {
  const {
    anchorEl,
    handleMenuClose,
    handleMenuItemClick,
    index,
  } = props;

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <StyledMenuItem
        onClick={() => handleMenuItemClick("edit", index)}
      >
        Edit
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => handleMenuItemClick("delete", index)}
      >
        Delete
      </StyledMenuItem>
    </StyledMenu>
  );
};

const PoolCard = (props) => {
  const { accessList, password, workIdentifier, index } =
    props;

  const {
    setOpenAddConfigModal,
    mockData,
    siteSelectedName,
    setMockData,
    setPoolDataToEdit,
    setEditModeOn,
    deletionDialogMessage,
    deletionDialogOpen,
    setDeletionDialogOpen,
    setDeleteDialogHeaderMessage,
    setDeletionDialogMessage,
    deleteDialogHeaderMessage,
  } = usePoolsPageContext();

  const { setToastOpen, setToastMessage } =
    useAppContextProvider();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    if (option === "edit") {
      const data = {
        index: index,
        accessLists: accessList,
        password: password,
        workIdentifier: workIdentifier,
      };
      console.log("editable", data);
      setPoolDataToEdit((prev) => {
        console.log("prev", prev, data);
        return { ...data };
      });
      setOpenAddConfigModal(true);
      setEditModeOn(true);
      // setConfigModalInEditMode(true);
    }
    if (option === "delete") {
      handleMenuClose();
      setDeletionDialogOpen(true);
      setDeleteDialogHeaderMessage(
        `Delete Pool Configuration ${index + 1}`
      );
      setDeletionDialogMessage(
        "Are you sure you want to delete this pool configuration? This action cannot be undone."
      );
    }
  };
  const handleDeletionDialogClose = () => {
    setDeletionDialogOpen(false);
  };

  const handlePoolConfigurationDeletion = () => {
    const newMockData = [...mockData];
    const unModifiedRest = newMockData?.filter((site) => {
      return (
        site?.label.toLowerCase() !==
        siteSelectedName.toLowerCase()
      );
    });
    const modifiedLatestMock = newMockData?.find((site) => {
      return (
        site?.label.toLowerCase() ===
        siteSelectedName.toLowerCase()
      );
    });
    const pools = modifiedLatestMock?.pools;
    const modifiedPools = pools?.filter(
      (pool, poolIndex) => poolIndex !== index
    );
    modifiedLatestMock.pools = [...modifiedPools];

    setMockData([modifiedLatestMock, ...unModifiedRest]);
    setToastOpen(true);
    setToastMessage(
      `Pool ${index + 1} deleted from ${siteSelectedName}`
    );

    handleDeletionDialogClose();
  };
  console.log(accessList);

  return (
    <Box
      display={"grid"}
      gap={"1.5rem"}
      sx={{
        padding: "1.25rem 2rem 2rem 2rem",
        borderRadius: "1.5rem",
        backgroundColor: "#EEEDF7",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <H7TitleLarge>{`Pool ${index + 1}`}</H7TitleLarge>
        <StyledIconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </StyledIconButton>
        <PoolCardMenu
          {...{
            anchorEl,
            handleMenuClose,
            handleMenuItemClick,
            index,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 3fr", // Flexible columns
          gap: "1rem",
        }}
      >
        {[
          { label: "URL", value: accessList.url },
          { label: "User", value: accessList.user },
          { label: "Password", value: password },
          {
            label: "Worker Identifier",
            value: workIdentifier,
          },
        ].map(({ label, value }, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <H9TitleSmall>{label}</H9TitleSmall>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <BodyLarge>{value}</BodyLarge>
            </Box>
          </React.Fragment>
        ))}
      </Box>
      <ActionDialog
        open={deletionDialogOpen}
        onClose={handleDeletionDialogClose}
        headerMessage={deleteDialogHeaderMessage}
        subMessage={deletionDialogMessage}
        deleteAction={handlePoolConfigurationDeletion}
        buttonText={"Delete"}
      />
    </Box>
  );
};

export default PoolCard;
