/**
 * Renders a tree view of sites and groups.
 * @param {Object} props - The component props.
 * @param {Array} props.siteAndGroups - The array of site and group data.
 * @param {Function} props.onSitesAndGroupSearch - The callback function for searching sites and groups.
 * @returns {JSX.Element} The rendered component.
 */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grow,
  Collapse,
  Fade,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { StyledIconButton } from "../../../../components/StyledComponents/Buttons/AuradineButtons.js";
import {
  BodyLarge,
  LabelSmall,
} from "../../../../components/StyledComponents/Typography/Typography.tsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Close from "@mui/icons-material/Close";
import { StyledCheckbox } from "../../../../components/StyledComponents/Inputs/Inputs.js";
import { check } from "prettier";

/**The SiteAndGroupTreeItem component takes in several props:
site: Represents the current site.
setSiteSelected: A function to update the selected site.
siteSelected: Represents the currently selected site.
setSiteSelectedName: A function to update the name of the selected site.
setGroupSelectedName: A function to update the name of the selected group.
siteSelectedName: Represents the name of the currently selected site.
groupSelectedName: Represents the name of the currently selected group. */

//Inside the component, there is a state variable called treeOpen initialized with a value of false. This state variable will be used to control the visibility of the tree.
const SiteAndGroupTreeItem = (props) => {
  const {
    site,
    setSiteSelected,
    siteSelected,
    setSiteSelectedName,
    setGroupSelectedName,
    siteSelectedName,
    groupSelectedName,
    clickDisabled,
    multiSelectModel,
    setSiteAndGroups,
    siteAndGroups,
    setNumberOfSelections,
    numberOfSelections,
  } = props;
  const [treeOpen, setTreeOpen] = useState(false);
  let allGroupSelectedInSite = false;

  const onSiteSelectedMatriceChange = (e) => {
    if (e.target.checked) {
      const siteCount = 1;
      const groupCount = site.groups.reduce((acc, curr) => {
        return acc + 1;
      }, 0);
      if (
        site.groups.some((group) => group.selectedGroup)
      ) {
        const groupCount = site.groups.reduce(
          (acc, curr) => {
            return !curr.selectedGroup ? acc + 1 : acc;
          },
          0
        );
        setNumberOfSelections(
          (prev) => prev + siteCount + groupCount
        );
      } else {
        setNumberOfSelections(
          (prev) => prev + siteCount + groupCount
        );
      }
      const siteModifications = {
        ...site,
        selectedSite: e.target.checked,
        groups: site.groups.map((group) => ({
          ...group,
          selectedGroup: true,
        })),
      };
      const siteGroupInfo = [...siteAndGroups];
      const indexToReplaceAt = siteGroupInfo.findIndex(
        (siteGroup) => site.siteName === siteGroup.siteName
      );
      siteGroupInfo.splice(
        indexToReplaceAt,
        1,
        siteModifications
      );
      setSiteAndGroups(siteGroupInfo);
    } else {
      const siteModifications = {
        ...site,
        selectedSite: e.target.checked,
        groups: site.groups.map((group) => ({
          ...group,
          selectedGroup: false,
        })),
      };
      const siteCount = 1;
      const groupCount = site.groups.reduce((acc, curr) => {
        return acc + 1;
      }, 0);
      setNumberOfSelections((prev) =>
        prev - siteCount - groupCount <= 0
          ? 0
          : prev - siteCount - groupCount
      );
      const siteGroupInfo = [...siteAndGroups];
      const indexToReplaceAt = siteGroupInfo.findIndex(
        (siteGroup) => site.siteName === siteGroup.siteName
      );
      siteGroupInfo.splice(
        indexToReplaceAt,
        1,
        siteModifications
      );
      setSiteAndGroups(siteGroupInfo);
    }
  };

  const checkAllGroupsSelected = () => {
    return (
      site.groups.length > 0 &&
      site.groups?.every((group) => group.selectedGroup)
    );
  };

  const checkAllGroupsDeselected = () => {
    return (
      site.groups.length > 0 &&
      site.groups?.every((group) => !group.selectedGroup)
    );
  };

  const onGroupSelectedMatriceChange = (e, index) => {
    if (e.target.checked) {
      console.log("in here");
      const siteModifications = {
        ...site,
        groups: site.groups.map((group, i) =>
          i === index
            ? { ...group, selectedGroup: e.target.checked }
            : group
        ),
      };

      if (
        siteModifications.groups.every(
          (group) => group.selectedGroup
        )
      ) {
        console.log("");
        siteModifications.selectedSite = true;
        setNumberOfSelections((prev) => prev + 2);
      } else {
        setNumberOfSelections((prev) => prev + 1);
      }

      const siteGroupInfo = [...siteAndGroups];
      const indexToReplaceAt = siteGroupInfo.findIndex(
        (siteGroup) => site.siteName === siteGroup.siteName
      );
      siteGroupInfo.splice(
        indexToReplaceAt,
        1,
        siteModifications
      );
      setSiteAndGroups(siteGroupInfo);
    } else {
      const siteModifications = {
        ...site,
        groups: site.groups.map((group, i) =>
          i === index
            ? { ...group, selectedGroup: e.target.checked }
            : group
        ),
      };

      if (
        siteModifications.groups.some(
          (group) =>
            !group.selectedGroup &&
            siteModifications.selectedSite
        )
      ) {
        siteModifications.selectedSite = false;
        setNumberOfSelections((prev) => prev - 2);
      } else {
        setNumberOfSelections((prev) => prev - 1);
      }
      const siteGroupInfo = [...siteAndGroups];
      const indexToReplaceAt = siteGroupInfo.findIndex(
        (siteGroup) => site.siteName === siteGroup.siteName
      );
      siteGroupInfo.splice(
        indexToReplaceAt,
        1,
        siteModifications
      );
      setSiteAndGroups(siteGroupInfo);
    }
  };

  const checkAtleastOneGroupSelected = () => {
    return (
      site.groups.length > 0 &&
      site.groups?.some((group) => group.selectedGroup)
    );
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      borderBottom={"1px solid  #C4C5D6"}
    >
      <Box
        className={"site_information"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={" 0.5rem 1.5rem 0.5rem 1rem"}
        onClick={() => {
          if (!clickDisabled) {
            setSiteSelected(true);
            setSiteSelectedName(site?.siteName);
            setGroupSelectedName("");
          }
        }}
        gap={4}
        sx={{
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          backgroundColor:
            siteSelectedName === site?.siteName &&
              siteSelected
              ? "#C7D1FF"
              : "",
          borderRadius: "6.25rem",
          "&:hover": {
            backgroundColor:
              siteSelectedName === site?.siteName &&
                siteSelected
                ? "#C7D1FF"
                : "rgba(26, 27, 34, 0.08)",
            borderRadius: "6.25rem",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          flexGrow={1}
          alignItems={"center"}
        >
          {siteSelectedName === site?.siteName &&
            siteSelected && (
              <StyledIconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setGroupSelectedName("");
                  setSiteSelectedName("");
                  setSiteSelected(false);
                }}
              >
                <Close
                  fontSize="small"
                  sx={{ color: "#000000" }}
                />
              </StyledIconButton>
            )}
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            {multiSelectModel && (
              <StyledCheckbox
                indeterminate={
                  checkAtleastOneGroupSelected() &&
                  !checkAllGroupsSelected()
                }
                checked={
                  site.selectedSite ||
                  checkAllGroupsSelected()
                }
                onChange={onSiteSelectedMatriceChange}
              />
            )}
            <BodyLarge>{site?.siteName}</BodyLarge>{" "}
          </Box>
          <LabelSmall> {site?.deviceCount} </LabelSmall>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={2.48}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <StyledIconButton
            onClick={(event) => {
              event.stopPropagation();
              setTreeOpen((prev) => !prev);
            }}
            disabled={
              site?.groups && site?.groups?.length === 0
            }
          >
            {treeOpen ? (
              <>
                <ArrowDropDownIcon />
              </>
            ) : (
              <>
                <ArrowDropUpIcon />
              </>
            )}
          </StyledIconButton>
        </Box>
      </Box>
      {treeOpen &&
        site?.groups &&
        site?.groups?.length > 0 &&
        site.groups.map((groups, index) => {
          return (
            <Fade in={treeOpen} key={index}>
              <Box
                className={"group_information"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={" 0.5rem 1.5rem 0.5rem 1rem"}
                gap={4}
                onClick={() => {
                  if (!clickDisabled) {
                    setSiteSelected(false);
                    setGroupSelectedName(groups?.DGName);
                    setSiteSelectedName(site?.siteName);
                  }
                }}
                sx={{
                  cursor: "pointer",
                  transition: "all 0.3s ease-in-out",
                  borderRadius: "6.25rem",
                  backgroundColor:
                    groupSelectedName === groups?.DGName &&
                      siteSelectedName === site?.siteName &&
                      !siteSelected
                      ? "#C7D1FF"
                      : "",
                  "&:hover": {
                    backgroundColor:
                      groupSelectedName ===
                        groups?.DGName &&
                        siteSelectedName === site?.siteName &&
                        !siteSelected
                        ? "#C7D1FF"
                        : "rgba(26, 27, 34, 0.08)",
                    borderRadius: "6.25rem",
                    // padding: "1rem 1.5rem",
                  },
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  flexGrow={1}
                  alignItems={"center"}
                >
                  {groupSelectedName === groups?.DGName &&
                    siteSelectedName === site?.siteName &&
                    !siteSelected && (
                      <StyledIconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setGroupSelectedName("");
                          setSiteSelectedName("");
                          setSiteSelected(false);
                        }}
                      >
                        <Close
                          fontSize="small"
                          sx={{ color: "#000000" }}
                        />
                      </StyledIconButton>
                    )}
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={2}
                  >
                    {multiSelectModel && (
                      <Box width={50}></Box>
                    )}
                    {multiSelectModel && (
                      <StyledCheckbox
                        checked={
                          site.groups[index].selectedGroup
                        }
                        onChange={(e) =>
                          onGroupSelectedMatriceChange(
                            e,
                            index
                          )
                        }
                      />
                    )}
                    <BodyLarge>{groups?.DGName}</BodyLarge>{" "}
                  </Box>
                  <LabelSmall>
                    {" "}
                    {groups?.deviceCount}{" "}
                  </LabelSmall>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={2.48}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Box
                    width={"2.125rem"}
                    height={"2.125rem"}
                  ></Box>
                </Box>
              </Box>
            </Fade>
          );
        })}
    </Box>
  );
};

//A  group tree is a hierarchical representation of sites and groups from our groups and sites management system.
const SiteAndGroupTree = (props) => {
  const {
    siteAndGroups,
    setSiteAndGroups,
    onSitesAndGroupSearch,
    clickDisabled,
    multiSelectModel,
    maxHeight,
    setNumberOfSelections,
    numberOfSelections,
    isSiteGroupDataFetching,
    isSiteGroupDataLoading,
    isSiteGroupDataFetched,
  } = props;
  const [treeOpen, setTreeOpen] = React.useState(false);
  const [siteSelected, setSiteSelected] = useState(false);
  // const [groupSelected, setGroupSelected] = useState(false);
  const [expansionDisabled, setExpansionDisabled] =
    useState(false);
  const [siteSelectedName, setSiteSelectedName] =
    useState("");
  const [groupSelectedName, setGroupSelectedName] =
    useState("");

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      flexGrow={1}
      overflow={"auto"}
      maxHeight={
        maxHeight ? maxHeight : "calc(100vh - 23rem)"
      }
    >
      {isSiteGroupDataFetched && siteAndGroups.length > 0 &&
        siteAndGroups?.map((site, index) => (
          <SiteAndGroupTreeItem
            key={index}
            site={site}
            treeOpen={treeOpen}
            setTreeOpen={setTreeOpen}
            siteSelected={siteSelected}
            setSiteSelected={setSiteSelected}
            siteSelectedName={siteSelectedName}
            setSiteSelectedName={setSiteSelectedName}
            groupSelectedName={groupSelectedName}
            setGroupSelectedName={setGroupSelectedName}
            clickDisabled={clickDisabled}
            multiSelectModel={multiSelectModel}
            setSiteAndGroups={setSiteAndGroups}
            siteAndGroups={siteAndGroups}
            setNumberOfSelections={setNumberOfSelections}
            numberOfSelections={numberOfSelections}
          />
        ))}

      { }

      {isSiteGroupDataLoading && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {" "}
          <CircularProgress
            size={"2rem"}
            sx={{
              color: "#2D55C9",
            }}
          />
        </Box>
      )}

      {/* push this out of siteGroup */}
      {isSiteGroupDataFetched &&
        siteAndGroups.length === 0 && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexGrow={1}
          >
            <BodyLarge>No searches found</BodyLarge>
          </Box>
        )}
    </Box>
  );
};

export default SiteAndGroupTree;
