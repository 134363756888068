// @ts-nocheck
import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Container,
  LinearProgress,
} from "@mui/material";
import { LeftSidePanel } from "./Components/LeftPanel/LeftPanel.js";
import "reactjs-popup/dist/index.css";
import useAppContextProvider from "../AppContext/useAppContextProvider.js";

import { useHistory, useLocation } from "react-router-dom";
import {
  BodyLarge,
  H7TitleLarge,
} from "../components/StyledComponents/Typography/Typography.tsx";

import usePoolsPageContext from "./context/usePoolsPageContext.js";
import { useQuery } from "@tanstack/react-query";
import { GetUserUseQuery } from "../api/api.js";
import { PoolsPageHeader } from "./Components/PoolPageHeader/PoolsPageHeader.js";
import PoolNotSelectedState from "../components/StyledComponents/EmptyStates/PoolNotSelectedState.tsx";
import NoPoolsCreatedState from "../components/StyledComponents/EmptyStates/NoPoolsCreatedState.tsx";
import PoolCard from "./Components/PoolCard/PoolCard.js";
import {
  PrimaryButton,
  SecondaryButton,
} from "../components/StyledComponents/Buttons/AuradineButtons.js";

export const HeaderContainer = React.memo((props) => {
  const { data } = props;
  const { siteSelectedName, mockData } =
    usePoolsPageContext();
  const history = useHistory();
  // const shouldAddPoolConfigurationEnabled =
  //   data?.find(
  //     (site) =>
  //       site.label?.toLowerCase() === siteSelectedName
  //   )[0]?.pools.length < 4;
  return (
    <Box
      className={"header-container"}
      display={"flex"}
      justifyContent="space-between"
      flexDirection="column"
      gap={4}
      marginBottom={"1.5rem"}
    >
      <Box
        className={"title-box"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={3.5}
      >
        <H7TitleLarge>Pool Configuration</H7TitleLarge>
        <BodyLarge>
          View the mining pool configuration for your site.
        </BodyLarge>
      </Box>
    </Box>
  );
});

/**
 * Represents the MinerPage component.
 * This component displays a table of miner devices and provides functionality to interact with them.
 */
export default function PoolsPage() {
  const [leftSidePanelOpen, setLeftSidePanelOpen] =
    React.useState(false);
  const [gridHeight, setGridHeight] = React.useState(0);
  const tableContainerRef = React.useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { authToken } = useAppContextProvider();
  const { siteSelectedName, mockData } =
    usePoolsPageContext();

  // const [skip, setSkip] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const limit = 25;
  //fetching to get userPreferences to set the tableGroup and detailGroup from user object. If not present, we are setting default values

  const {
    data: userInfo,
    isFetched: isUserInfoFetched,
    isFetching: isUserInfoFetching,
  } = useQuery({
    queryKey: ["userInfo", authToken],
    queryFn: () => GetUserUseQuery(authToken),
    enabled: !!authToken,
  });
  const user = userInfo?.user;

  const siteNotFound = (siteId) => {
    const site = mockData?.find(
      (site) =>
        site.label?.toLowerCase() === siteId?.toLowerCase()
    );
    return site && site.pools.length === 0;
  };

  const findSiteIndex = (siteId) => {
    return mockData?.findIndex(
      (site) =>
        site.label?.toLowerCase() === siteId?.toLowerCase()
    );
  };

  return (
    //@ts-nocheck
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <HeaderContainer />
      <Grid
        container
        className={"miner-page-table-container"}
        display={"flex"}
        flexDirection={"row"}
        spacing={4}
        sx={{ transition: "all 0.3s ease-in-out" }}
      >
        <Grid
          ref={tableContainerRef}
          item
          xs={12}
          sx={{
            transition: "width 0.3s ease-in-out", // Smooth transition for width
          }}
        >
          <Box
            className={"miner-page-table"}
            display={"flex"}
            flexDirection={"row"}
            backgroundColor={"#F3F2FD"}
            padding={"0.75rem 0  0.75rem 0"}
            borderRadius={"1rem"}
            sx={{
              transition: "width 0.3s ease-in-out", // Smooth transition for width
            }}
          >
            <LeftSidePanel
              leftSidePanelOpen={leftSidePanelOpen}
              setLeftSidePanelOpen={setLeftSidePanelOpen}
              height={gridHeight}
            />
            <Box
              className={"table-container"}
              display={"flex"}
              flexDirection={"column"}
              padding={"0 0.75rem 0 0.75rem"}
              height={"calc(100vh - 13rem)"}
              // overflow={"auto"}
              minWidth={"0"}
              gap={4}
              flex={3}
              flexShrink={1}
            >
              <PoolsPageHeader
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
                data={mockData}
              />
              <Box
                className={"Datagrid-container"}
                display={"flex"}
                flexShrink={1}
                flexGrow={1}
                overflowY={"auto"}
                height="calc(100vh - 10rem)"
              >
                {!siteSelectedName && (
                  <PoolNotSelectedState
                    message={
                      "Pick a site to see pool configurations"
                    }
                  />
                )}
                {siteSelectedName &&
                  siteNotFound(siteSelectedName) && (
                    <NoPoolsCreatedState
                      message={
                        "You have no pools assigned to this site yet. Add a pool configuration using the “Add” button."
                      }
                    />
                  )}
                {siteSelectedName &&
                  !siteNotFound(siteSelectedName) && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={4}
                      flexGrow={1}
                      maxHeight={"calc(100vh - 18rem)"}
                      style={{ overflowY: "scroll" }}
                    >
                      {siteSelectedName &&
                        !siteNotFound(siteSelectedName) &&
                        mockData &&
                        mockData[
                          findSiteIndex(siteSelectedName)
                        ]?.pools?.map((pool, index) => {
                          if (pool)
                            return (
                              <PoolCard
                                accessList={
                                  pool?.accessLists
                                }
                                password={pool?.password}
                                workIdentifier={
                                  pool?.workIdentifier
                                }
                                index={index}
                                key={index}
                              />
                            );
                          else <></>;
                        })}
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
