/**
 * AddDemandResponseDialog component renders a modal dialog for scheduling a power curtail operation.
 * It includes a header with a title and a close button, and a form for selecting the scheduling options.
 *
 * @component
 * @example
 * return (
 *   <AddDemandResponseDialog />
 * )
 *
 * @returns {JSX.Element} The rendered AddDemandResponseDialog component.
 */
import React, {
  Component,
  useEffect,
  useState,
} from "react";
import {
  StyledIconButton,
  PrimaryButton,
  SecondaryButton,
  StyledButtonGroup,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";

import {
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CheckIcon from "@mui/icons-material/Check";
import { StyledDialog } from "../../../components/StyledComponents/Dialog/Dialog.js";
import {
  H6HeadlineSmall,
  H8TitleMedium,
  LabelLarge,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Box,
  Button,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import useDemandResponseContext from "../../context/useDemandResponseContext.js";
import { Controller, useForm } from "react-hook-form";
import { addMinutes, format, set } from "date-fns";
import dayjs from "dayjs";
import {
  StyledTextField,
  StyledPopper,
  StyledPaper,
  StyledFormControl,
} from "../../../components/StyledComponents/Inputs/Inputs.js";
import PlaceHolderButton from "../../../components/PlaceHolderButton/PlaceHolderButton.js";

const AddDemandConfigModalHeader = () => {
  const {
    openPowerCurtailOperationModal,
    setOpenPowerCurtailOperationModal,
    editModeOn,
    setEditModeOn,
    eventModalData,
    setEventModalData,
  } = useDemandResponseContext();
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      flexGrow={1}
      padding={"1rem 0"}
    >
      <H6HeadlineSmall>
        {editModeOn
          ? `Edit ${eventModalData?.id}`
          : "Add Power Curtail Operation"}
      </H6HeadlineSmall>
      <StyledIconButton
        onClick={() => {
          setOpenPowerCurtailOperationModal(false);
          setEditModeOn(false);
          setEventModalData([]);
        }}
      >
        <CloseIcon />
      </StyledIconButton>
    </Box>
  );
};

const AddDemandConfigModal = () => {
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();
  const {
    openPowerCurtailOperationModal,
    setOpenPowerCurtailOperationModal,
    setCalendarEvents,
    calendarEvents,
    eventModalData,
    editModeOn,
  } = useDemandResponseContext();
  console.log(eventModalData);
  const defaultStartTime = dayjs().add(10, "minute");
  const defaultEndTime = dayjs().add(20, "minute");
  const {
    watch,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      powerCurtailOperationSchedule: "now",
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      power: null,
      rampUpDownTime: null,
      siteConfig: [],
    },
  });

  // Reset form values when eventModalData changes
  useEffect(() => {
    if (eventModalData) {
      reset({
        powerCurtailOperationSchedule:
          eventModalData.powerCurtailOperationSchedule ||
          "now",
        startTime: eventModalData.start
          ? dayjs(eventModalData.start)
          : defaultStartTime,
        endTime: eventModalData.end
          ? dayjs(eventModalData.end)
          : defaultEndTime,
        power: parseFloat(eventModalData.power) || null,
        rampUpDownTime:
          parseInt(eventModalData.rampUpDownTime) || null,
      });
    }
  }, [eventModalData]);
  const siteOptions = [
    "site1",
    "site2",
    "site3",
    "site4",
    "site5",
  ];

  const watchPowerCurtailOperationSchedule = watch(
    "powerCurtailOperationSchedule"
  );
  const watchStartTime = watch("startTime");
  const watchEndTime = watch("endTime");
  const watchPower = watch("power");
  const watchRampUpDownTime = watch("rampUpDownTime");
  const selectedOption = watch("siteConfig");
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    return result;
  }
  const handleSiteConfigDeletion = (optionToDelete) => {
    const newValue = selectedOption.filter(
      (option) => option !== optionToDelete
    );
    setValue("siteConfig", newValue);
  };

  const onsubmit = (data) => {
    if (!editModeOn) {
      const finalData =
        watchPowerCurtailOperationSchedule ===
          "schedule" && {
          source: "manual",
          id: generateRandomString(16),
          start: data.startTime?.toDate(),
          end: data.endTime?.toDate(),
          startDay: format(
            data.startTime.toDate(),
            "dd/MM/yyyy"
          ),
          endDay: format(
            data.endTime.toDate(),
            "dd/MM/yyyy"
          ),
          title: "random",
          color: "#2D55C9",
          ...data,
        };
      console.log(finalData);
      setCalendarEvents([finalData, ...calendarEvents]);
      setToastMessage("Scheduled successfully");
      setToastOpen(true);
      setOpenPowerCurtailOperationModal(false);
    } else {
      const calendarEventsCopy = [...calendarEvents];
      const index = calendarEventsCopy.findIndex(
        (calenderEvent) =>
          calenderEvent.id === eventModalData.id
      );
      const finalData =
        watchPowerCurtailOperationSchedule ===
          "schedule" && {
          source: "manual",
          id: generateRandomString(16),
          start: data.startTime?.toDate(),
          end: data.endTime?.toDate(),
          startDay: format(
            data.startTime.toDate(),
            "dd/MM/yyyy"
          ),
          endDay: format(
            data.endTime.toDate(),
            "dd/MM/yyyy"
          ),
          title: "random",
          color: "#2D55C9",
          ...data,
        };
      calendarEventsCopy[index] = finalData;
      setCalendarEvents(calendarEventsCopy);
      setToastMessage("Updated successfully");
      setToastOpen(true);
      setOpenPowerCurtailOperationModal(false);
    }
  };

  return (
    <StyledDialog
      maxWidth={"md"}
      fullWidth
      open={openPowerCurtailOperationModal}
      hideBackdrop
    >
      <AddDemandConfigModalHeader />
      <form
        style={{ overflow: "auto" }}
        onSubmit={handleSubmit(onsubmit)}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          className="demand-config-modal-body"
          gap={4}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            className="scheduling-decision-container"
            gap={4}
          >
            <H8TitleMedium>When</H8TitleMedium>
            <Controller
              name="powerCurtailOperationSchedule"
              control={control}
              defaultValue={
                watchPowerCurtailOperationSchedule
              }
              render={({ field }) => (
                <StyledToggleButtonGroup
                  exclusive
                  {...field}
                  onChange={(e, value) =>
                    field.onChange(value)
                  }
                  value={field.value}
                >
                  <StyledToggleButton
                    sx={{
                      textTransform: "none",
                      color: "#444653",
                      minWidth: "6rem",
                    }}
                    value={"now"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "center",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      {field.value === "now" && (
                        <CheckIcon
                          sx={{ marginBottom: "3%" }}
                          fontSize="small"
                        />
                      )}
                      <LabelLarge>Now</LabelLarge>
                    </Box>
                  </StyledToggleButton>
                  <StyledToggleButton
                    sx={{
                      textTransform: "none",
                      color: "#444653",
                      minWidth: "6rem",
                    }}
                    value={"schedule"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "center",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      {field.value === "schedule" && (
                        <CheckIcon
                          sx={{ marginBottom: "3%" }}
                          fontSize="small"
                        />
                      )}
                      <LabelLarge>Schedule</LabelLarge>
                    </Box>
                  </StyledToggleButton>
                  <StyledToggleButton
                    sx={{
                      textTransform: "none",
                      color: "#444653",
                      minWidth: "6rem",
                    }}
                    value={"standby"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {field.value === "standby" && (
                        <CheckIcon
                          sx={{ marginBottom: "3%" }}
                          fontSize="small"
                        />
                      )}
                      <LabelLarge
                        style={{ color: "#444653" }}
                      >
                        {" "}
                        Standby
                      </LabelLarge>
                    </Box>
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              )}
            />
          </Box>
          {
            <Box
              display={"grid"}
              gridTemplateRows={"auto auto auto"}
              gap={7.76}
            >
              {(watchPowerCurtailOperationSchedule ===
                "schedule" ||
                watchPowerCurtailOperationSchedule ===
                  "standby") && (
                <Box
                  display={"grid"}
                  gridTemplateColumns={"1fr 1fr"}
                  gap={4}
                >
                  <Controller
                    name="startTime"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (value > watchEndTime) {
                          return "Start time should be before end time";
                        }
                        if (value < dayjs()) {
                          return "Start time should be in the future";
                        }
                        if (
                          value < dayjs().add(10, "minute")
                        ) {
                          return "Start time should be at least 10 minutes from now";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <StyledFormControl>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                        >
                          <DateTimePicker
                            label="Start Time"
                            value={field.value}
                            slots={{
                              desktopPaper: StyledPaper,
                            }}
                            onChange={(date) =>
                              field.onChange(date)
                            }
                            slotProps={{
                              textField: {
                                error: Boolean(
                                  errors.startTime
                                ),
                                helperText: errors.startTime
                                  ? errors.startTime.message
                                  : "MM/DD/YYYY at HH:MM am/pm",
                              },
                              day: {
                                sx: {
                                  "&.MuiPickersDay-root.Mui-selected":
                                    {
                                      backgroundColor:
                                        "#2D55C9",
                                    },
                                },
                              },
                              digitalClockSectionItem: {
                                sx: {
                                  "&.Mui-selected": {
                                    backgroundColor:
                                      "#2D55C9",
                                    borderRadius: "50%",
                                  },
                                },
                              },
                            }}
                            renderInput={(props) => (
                              <StyledTextField
                                {...props}
                                variant="outlined"
                                error={Boolean(
                                  errors.startTime
                                )}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </StyledFormControl>
                    )}
                  />
                  <Controller
                    name="endTime"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) return true;
                        if (value < watchStartTime) {
                          return "End time should be after start time";
                        }
                        if (value < dayjs()) {
                          return "End time should be in the future";
                        }
                        if (
                          value < dayjs().add(20, "minute")
                        ) {
                          return "End time should be at least 20 minutes from now";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <StyledFormControl>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                        >
                          <DateTimePicker
                            label="End Time"
                            // defaultValue={defaultEndTime}
                            value={field.value}
                            slots={{
                              desktopPaper: StyledPaper,
                            }}
                            onChange={(date) =>
                              field.onChange(date)
                            }
                            slotProps={{
                              textField: {
                                error: Boolean(
                                  errors.endTime
                                ),
                                helperText: errors.endTime
                                  ? errors.endTime.message
                                  : "MM/DD/YYYY at HH:MM am/pm",
                              },
                              day: {
                                sx: {
                                  "&.MuiPickersDay-root.Mui-selected":
                                    {
                                      backgroundColor:
                                        "#2D55C9",
                                    },
                                },
                              },
                              digitalClockSectionItem: {
                                sx: {
                                  "&.Mui-selected": {
                                    backgroundColor:
                                      "#2D55C9",
                                    borderRadius: "50%",
                                  },
                                },
                              },
                            }}
                            renderInput={(props) => (
                              <StyledTextField
                                {...props}
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </StyledFormControl>
                    )}
                  />
                </Box>
              )}
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                className="sitesContainer"
              >
                <H8TitleMedium>
                  Select Sites to schedule this event for.
                </H8TitleMedium>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                >
                  <StyledFormControl
                    fullWidth
                    margin="none"
                  >
                    {/* <InputLabel htmlFor="">Status</InputLabel> */}
                    <Controller
                      name="siteConfig"
                      control={control}
                      defaultValue={null}
                      render={({
                        field: { onChange, value, ref },
                      }) => (
                        <Autocomplete
                          fullWidth
                          multiple
                          disableCloseOnSelect
                          onChange={(_, data) =>
                            onChange(data)
                          }
                          value={value || []}
                          clearIcon={null}
                          options={siteOptions} // Replace with your options
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              label="Site Name"
                              variant="outlined"
                              inputRef={ref}
                              sx={{ minHeight: "2.5rem" }}
                            />
                          )}
                          renderTags={() => null}
                        />
                      )}
                    />
                  </StyledFormControl>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={4}
                  maxWidth={"62.5rem"}
                  flexWrap={"wrap"}
                >
                  {selectedOption?.map((site, index) => (
                    <PlaceHolderButton
                      key={index}
                      onClose={() =>
                        handleSiteConfigDeletion(site)
                      }
                      text={site}
                    />
                  ))}
                </Box>
              </Box>
              {(watchPowerCurtailOperationSchedule ===
                "now" ||
                watchPowerCurtailOperationSchedule ===
                  "schedule") && (
                <Box
                  display={"grid"}
                  gridTemplateRows={"auto auto"}
                  gap={4}
                >
                  <H8TitleMedium>
                    Change Power
                  </H8TitleMedium>
                  <Controller
                    name="power"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value || value === 0)
                          return true;
                        if (
                          value < 0.001 ||
                          value > 0.004
                        ) {
                          return "Power should be between 0.001 and 0.004 MW";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <StyledTextField
                        margin="none"
                        variant="outlined"
                        label="In MW"
                        {...field}
                        error={Boolean(errors.power)}
                        helperText={
                          errors.power
                            ? errors.power.message
                            : "Enter a number between 0.0010 and 0.0040"
                        }
                      />
                    )}
                  />
                </Box>
              )}
              {(watchPowerCurtailOperationSchedule ===
                "standby" ||
                watchPowerCurtailOperationSchedule ===
                  "schedule") && (
                <Box
                  display={"grid"}
                  gridTemplateRows={"auto auto"}
                  gap={4}
                >
                  <H8TitleMedium>
                    Ramp Up/Down Time
                  </H8TitleMedium>
                  <Controller
                    name="rampUpDownTime"
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) return true;
                        if (value < 120 || value > 86400) {
                          return "Power should be between 120 and 86,400";
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <StyledTextField
                        margin="none"
                        variant="outlined"
                        label="In seconds"
                        {...field}
                        error={Boolean(
                          errors.rampUpDownTime
                        )}
                        helperText={
                          errors.rampUpDownTime
                            ? errors.rampUpDownTime.message
                            : "Enter a number between 120 and 86,400"
                        }
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          }
        </Box>
        <DialogActions sx={{ paddingTop: "1rem" }}>
          <PrimaryButton type="submit">
            Schedule
          </PrimaryButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

const AddDemandResponseDialog = (props) => {
  return (
    <>
      <AddDemandConfigModal />
    </>
  );
};

export default AddDemandResponseDialog;
