import {
  StyledDialog,
  StyledDialogTitle,
} from "../../components/StyledComponents/Dialog/Dialog.js";
import {
  BodyMedium,
  H6HeadlineSmall,
} from "../../components/StyledComponents/Typography/Typography.tsx";

import React from "react";
import { Box, DialogContent } from "@mui/material";
import {
  PrimaryButton,
  SecondaryButton,
} from "../StyledComponents/Buttons/AuradineButtons.js";

import useAppContextProvider from "../../AppContext/useAppContextProvider.js";

const ActionDialog = ({
  open,
  onClose,
  headerMessage,
  subMessage,
  buttonText,
  deleteAction,
  extraInfo,
  ExtraInfoComponent,
}) => {
  console.log(open);
  return (
    <StyledDialog
      maxWidth="sm"
      hideBackdrop
      open={open}
      onClose={onClose}
      sx={{ overflowX: "hidden" }}
      padding={"1.5rem"}
    >
      <StyledDialogTitle>
        <H6HeadlineSmall style={{ textAlign: "left" }}>
          {headerMessage}
        </H6HeadlineSmall>
      </StyledDialogTitle>
      <DialogContent
        sx={{ padding: 0, overflowX: "hidden" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={6}
          marginTop={1}
        >
          <BodyMedium>{subMessage}</BodyMedium>
          {extraInfo && <ExtraInfoComponent />}
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={4}
            justifyContent={"flex-end"}
          >
            <SecondaryButton onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              sx={{ padding: "1rem" }}
              onClick={deleteAction}
            >
              {buttonText}
            </PrimaryButton>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default ActionDialog;
