import styled from "@emotion/styled";
import { LinearProgress } from "@mui/material";
import React from "react";

const StyledLinearProgress = styled(LinearProgress)(
  ({ theme }) => ({
    height: "0.25rem",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#2D55C9", // Progress color
    },

    "&.MuiLinearProgress-root": {
      borderRadius: 5,
      backgroundColor: "#d3d3d3 !important", // Track color // Progress color
    },
  })
);

export default StyledLinearProgress;
