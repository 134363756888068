import React from "react";
import {
  StyledIconButton,
  StyledToggleButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "@mui/icons-material/FilterList";
import { useState } from "react";
import {
  StyledDialog,
  StyledDialogTitle,
} from "../../../components/StyledComponents/Dialog/Dialog.js";
import {
  H5HeadlineMedium,
  H6HeadlineSmall,
  H8TitleMedium,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import { DialogContent, FormControl } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { StyledTextField } from "../../../components/StyledComponents/Inputs/Inputs.js";
import usePoolsPageContext from "../../context/usePoolsPageContext.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";

export const AddAccessListsDialog = () => {
  const {
    setAccessLists,
    setAccessListsCopy,
    openAccessListsDialog,
    accessLists,
    setOpenAccessListsDialog,
    editWhiteListsOn,
    whiteListToDelete,
  } = usePoolsPageContext();

  const { setToastMessage, setToastOpen } =
    useAppContextProvider();

  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        user: "",
        url: "",
      },
    });

  //set values to when
  React.useEffect(() => {
    if (editWhiteListsOn) {
      setValue("user", whiteListToDelete?.user);
      setValue("url", whiteListToDelete?.url);
    }
  }, [editWhiteListsOn, whiteListToDelete]);

  const onSubmit = (data) => {
    const finalData = {
      id: accessLists.length + 1,
      ...data,
    };
    if (!editWhiteListsOn) {
      setAccessLists((prev) => [...prev, finalData]);
      setAccessListsCopy((prev) => [...prev, finalData]);
      setOpenAccessListsDialog(false);
      setToastOpen(true);
      setToastMessage("Access list added successfully");
      reset();
    } else {
      setAccessLists((prev) => {
        const index = prev.findIndex(
          (item) => item.id === whiteListToDelete.id
        );
        const updatedList = [...prev];
        updatedList[index] = finalData;
        return updatedList;
      });
      setAccessListsCopy((prev) => {
        const index = prev.findIndex(
          (item) => item.id === whiteListToDelete.id
        );
        const updatedList = [...prev];
        updatedList[index] = finalData;
        return updatedList;
      });
      setOpenAccessListsDialog(false);
      setToastOpen(true);
      setToastMessage("Access list updated successfully");
      reset();
    }
  };

  return (
    <StyledDialog
      maxWidth={"lg"}
      open={openAccessListsDialog}
      hideBackdrop
    >
      <StyledDialogTitle sx={{ paddingTop: 0 }}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <H5HeadlineMedium style={{ textAlign: "left" }}>
            {!editWhiteListsOn
              ? `Add to your Access Trust List`
              : `Edit your Access Trust List`}
          </H5HeadlineMedium>
          <StyledIconButton
            onClick={() => setOpenAccessListsDialog(false)}
            style={{ textAlign: "right" }}
          >
            <CloseIcon />
          </StyledIconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={8}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="url-details-container"
            >
              <H8TitleMedium>Enter the user</H8TitleMedium>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
              >
                <FormControl fullWidth margin="none">
                  <Controller
                    name="user"
                    control={control}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        variant="outlined"
                        label="User"
                        fullWidth
                        onChange={(event) =>
                          field.onChange(event.target.value)
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="url-details-container"
            >
              <H8TitleMedium>Enter the URL</H8TitleMedium>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
              >
                <FormControl fullWidth margin="none">
                  <Controller
                    name="url"
                    control={control}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        variant="outlined"
                        label="URL"
                        fullWidth
                        onChange={(event) =>
                          field.onChange(event.target.value)
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              gap={4}
              className="action-button-container"
            >
              <PrimaryButton
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Add
              </PrimaryButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};
