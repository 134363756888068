/**
 * This component represents a dialog for adding a price configuration.
 * It allows the user to select an Independent System Operator, choose a power purchase agreement type,
 * select a prize zone, and review the form before submission.
 *
 * @component
 * @example
 * return (
 *   <AddPriceConfigDialog />
 * )
 */
import React, { useEffect } from "react";
import {
  StyledIconButton,
  PrimaryButton,
  SecondaryButton,
  StyledRadio,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";

import { useState } from "react";
import { StyledDialog } from "../../../components/StyledComponents/Dialog/Dialog.js";
import {
  BodyLarge,
  H6HeadlineSmall,
  H8TitleMedium,
  H9TitleSmall,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import usePriceConfigurationContext from "../../context/usePriceConfigurationContext.js";
import PlaceHolderButton from "../../../components/PlaceHolderButton/PlaceHolderButton.js";
import StyledLinearProgress from "../../../components/StyledComponents/Progress/Progress.js";
import { StyledMenuItem } from "../../../components/StyledComponents/Menu/Menu.tsx";
import {
  StyledCheckbox,
  StyledFormControl,
  StyledInputLabel,
  StyledOutlinedInput,
  StyledSelect,
  StyledTextField,
} from "../../../components/StyledComponents/Inputs/Inputs.js";
import { BorderColor } from "@mui/icons-material";
import { property } from "lodash";

const PriceConfigurationFormHeader = (props) => {
  const { setFormData } = props;
  const {
    setOpenAddPriceConfigModal,
    setActiveStep,
    setTotalSteps,
    setSiteConfigurationDataToEdit,
    setEditConfigModeEnabled,
    editConfigModeEnabled,
  } = usePriceConfigurationContext();

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      flexGrow={1}
      padding={"1rem 0"}
    >
      <H6HeadlineSmall>
        {!editConfigModeEnabled
          ? `Create a New Price Configuration`
          : `Edit Price Configuration`}
      </H6HeadlineSmall>
      <StyledIconButton
        onClick={() => {
          setOpenAddPriceConfigModal(false);
          setFormData({});
          setActiveStep(0);
          setTotalSteps(4);
          setSiteConfigurationDataToEdit(null);
          setEditConfigModeEnabled(false);
        }}
      >
        <CloseIcon />
      </StyledIconButton>
    </Box>
  );
};

const SystemOperatorForm = ({
  onNext,
  formData,
  setFormData,
}) => {
  const {
    priceConfig,
    setTotalSteps,
    siteConfigurationDataToEdit,
  } = usePriceConfigurationContext();
  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        independentSystemOperator:
          priceConfig?.independentSystemOperator ||
          formData?.independentSystemOperator ||
          "",
      },
    });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      setValue(
        "independentSystemOperator",
        siteConfigurationDataToEdit?.independentSystemOperator
      );
    }
  }, [siteConfigurationDataToEdit]);

  const selectedOption = watch("independentSystemOperator");

  const onSubmit = (data) => {
    const finalData =
      selectedOption?.toLowerCase() === "ercot"
        ? { ...data }
        : { powerPurchaseAgreement: "hedge", ...data };
    const totalSteps =
      selectedOption?.toLowerCase() === "none" ? 3 : 4;
    setTotalSteps(totalSteps);

    onNext(finalData);
  };
  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={4}
          paddingTop={"2.5rem"}
          paddingBottom={"2.5rem"}
        >
          <H8TitleMedium>
            {" "}
            Select an Independent System Operator
          </H8TitleMedium>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledFormControl component="fieldset">
              <Controller
                name="independentSystemOperator"
                control={control}
                rules={{
                  required: "Please select an option",
                }}
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="ERCOT"
                        control={<StyledRadio />}
                        label="ERCOT"
                      />
                      <FormControlLabel
                        value="none"
                        control={<StyledRadio />}
                        label="None"
                      />
                    </RadioGroup>
                  </>
                )}
              />
            </StyledFormControl>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          disabled={!selectedOption}
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const PowerPurchaseAgreementForm = ({
  formData,
  onNext,
  onBack,
  setFormData,
}) => {
  const { priceConfig, siteConfigurationDataToEdit } =
    usePriceConfigurationContext();
  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        powerPurchaseAgreement:
          priceConfig?.powerPurchaseAgreement ||
          formData?.powerPurchaseAgreement ||
          "",
      },
    });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      reset({
        powerPurchaseAgreement:
          siteConfigurationDataToEdit?.powerPurchaseAgreement,
      });
    }
  }, [siteConfigurationDataToEdit]);
  const onSubmit = (data) => {
    onNext(data);
  };

  const selectedOption = watch("powerPurchaseAgreement");
  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={4}
          paddingTop={"2.5rem"}
          paddingBottom={"2.5rem"}
        >
          <H8TitleMedium>
            {" "}
            Choose your Power Purchase Agreement type.
          </H8TitleMedium>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledFormControl component="fieldset">
              <Controller
                name="powerPurchaseAgreement"
                control={control}
                rules={{
                  required: "Please select an option",
                }}
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="Index"
                        control={<StyledRadio />}
                        label="Index"
                      />
                      <FormControlLabel
                        value="Hedge"
                        control={<StyledRadio />}
                        label="Hedge"
                      />
                    </RadioGroup>
                  </>
                )}
              />
            </StyledFormControl>
          </form>
        </Box>
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onBack}>
          Back
        </SecondaryButton>
        <PrimaryButton
          disabled={!selectedOption}
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const PrizeZoneForm = ({
  formData,
  onNext,
  onBack,
  setFormData,
}) => {
  const { priceConfig, siteConfigurationDataToEdit } =
    usePriceConfigurationContext();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prizeZone:
        priceConfig?.prizeZone || formData?.prizeZone || "",
      costOfElectricity:
        priceConfig?.costOfElectricity ||
        formData?.costOfElectricity,
      energyOptimizer:
        priceConfig?.energyOptimizer ||
        formData?.energyOptimizer ||
        false,
      turnOnPrice:
        priceConfig?.turnOnPrice || formData?.turnOnPrice,
      turnOffPrice:
        priceConfig?.turnOnPrice || formData?.turnOnPrice,
    },
  });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      setValue(
        "prizeZone",
        siteConfigurationDataToEdit?.prizeZone
      );
      setValue(
        "costOfElectricity",
        siteConfigurationDataToEdit?.costOfElectricity
      );
      setValue(
        "energyOptimizer",
        siteConfigurationDataToEdit?.energyOptimizer
      );
      setValue(
        "turnOnPrice",
        siteConfigurationDataToEdit?.turnOnPrice
      );
      setValue(
        "turnOffPrice",
        siteConfigurationDataToEdit.turnOffPrice
      );
    }
  }, [siteConfigurationDataToEdit]);
  const generateRandomNumber = () => {
    return Math.round(
      Math.random() * (70 - 60) + 60
    ).toFixed(2);
  };
  const { powerPurchaseAgreement } = formData;
  const onSubmit = (data) => {
    const electricityCost =
      powerPurchaseAgreement?.toLowerCase() === "index" &&
      !data?.energyOptimizer
        ? generateRandomNumber()
        : data?.costOfElectricity;
    const finalData = {
      ...data,
      costOfElectricity: electricityCost,
    };
    console.log(finalData);
    onNext(finalData);
  };

  const selectedOption =
    watch("prizeZone") || watch("costOfElectricity");

  const costOfElectricityObserver = watch(
    "costOfElectricity"
  );

  const tunrOnPriceObserver = watch("turnOnPrice");

  const turnOffPriceObserver = watch("turnOffPrice");

  const energyOptimizerSet = watch("energyOptimizer");
  useEffect(() => {
    if (energyOptimizerSet) {
      setValue("costOfElectricity", generateRandomNumber());
    }
  }, [energyOptimizerSet, setValue]);

  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={4}
        >
          {powerPurchaseAgreement?.toLowerCase() ===
            "index" && (
            <H8TitleMedium>
              {" "}
              Select your prize zone.
            </H8TitleMedium>
          )}
          {powerPurchaseAgreement.toLowerCase() ===
            "hedge" && (
            <H8TitleMedium>
              Enter your cost of electricity (Per MW Per
              Hour)
            </H8TitleMedium>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            {powerPurchaseAgreement?.toLowerCase() ===
              "index" && (
              <Box
                display={"grid"}
                gridTemplateRows={"auto auto auto auto"}
                gap={6}
              >
                <StyledFormControl fullWidth margin="none">
                  <StyledInputLabel>
                    Price zone
                  </StyledInputLabel>
                  <Controller
                    name="prizeZone"
                    control={control}
                    render={({ field }) => (
                      <StyledSelect
                        {...field}
                        sx={{ minHeight: "3.5rem" }}
                        variant="outlined"
                        label="Price Zone"
                        fullWidth
                        placeholder="Price Zone"
                        inputProps={{
                          sx: {
                            padding: "1rem 0.5rem",
                          },
                        }}
                        onChange={(event) =>
                          field.onChange(event.target.value)
                        }
                      >
                        <StyledMenuItem
                          value={"HB_HOUSTON"}
                        >
                          HB_HOUSTON
                        </StyledMenuItem>
                        <StyledMenuItem value={"HB_NORTH"}>
                          HB_NORTH
                        </StyledMenuItem>
                        <StyledMenuItem value={"HB_PAN"}>
                          HB_PAN
                        </StyledMenuItem>
                        <StyledMenuItem value={"HB_SOUTH"}>
                          HB_SOUTH
                        </StyledMenuItem>
                        <StyledMenuItem value={"HB_WEST"}>
                          HB_WEST
                        </StyledMenuItem>
                        <StyledMenuItem value={"LZ_AEN"}>
                          LZ_AEN
                        </StyledMenuItem>
                      </StyledSelect>
                    )}
                  />
                </StyledFormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="energyOptimizer"
                      control={control}
                      render={({ field }) => (
                        <StyledCheckbox
                          {...field}
                          checked={field.value}
                          onChange={(e) =>
                            field.onChange(e.target.checked)
                          }
                          // sx={{ marginLeft: "0.5%" }}
                        />
                      )}
                    />
                  }
                  label="Energy Optimizer"
                />
                {energyOptimizerSet && (
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    gap={8}
                  >
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 2fr"}
                      padding={"0.5rem"}
                      borderRadius={"0.5rem"}
                      sx={{ backgroundColor: "#E8E7F1" }}
                      gap={6}
                    >
                      <H9TitleSmall>
                        Last 15 minutes realtime strike
                        price :
                      </H9TitleSmall>
                      <BodyLarge>
                        {costOfElectricityObserver}
                      </BodyLarge>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateRows={"auto auto"}
                      gap={4}
                    >
                      <H8TitleMedium>
                        Turn on price
                      </H8TitleMedium>
                      <Controller
                        name="turnOnPrice"
                        control={control}
                        render={({ field }) => (
                          <StyledTextField
                            margin="none"
                            variant="outlined"
                            label="USD In MW"
                            {...field}
                            error={Boolean(
                              errors.turnOnPrice
                            )}
                            helperText={
                              "If shut down, Miners will resume mining if price drops below turn On Price"
                            }
                          />
                        )}
                      />
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateRows={"auto auto"}
                      gap={4}
                    >
                      <H8TitleMedium>
                        Turn off price
                      </H8TitleMedium>
                      <Controller
                        name="turnOffPrice"
                        rules={{
                          validate: (value) => {
                            if (
                              value < tunrOnPriceObserver
                            ) {
                              return "Turn off price must be greater than turn on price";
                            }
                            return true;
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <StyledTextField
                            margin="none"
                            variant="outlined"
                            label="USD In MW"
                            {...field}
                            error={Boolean(
                              errors.turnOffPrice
                            )}
                            helperText={
                              errors.turnOffPrice?.message
                                ? errors.turnOffPrice
                                    ?.message
                                : "If shut down, Miners will resume mining if price drops below turn On Price"
                            }
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {powerPurchaseAgreement?.toLowerCase() ===
              "hedge" && (
              <StyledFormControl fullWidth margin="none">
                {/*<InputLabel>Status</InputLabel>*/}
                <InputLabel htmlFor="cost-of-electricity">
                  In USD
                </InputLabel>
                <Controller
                  name="costOfElectricity"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <StyledOutlinedInput
                      {...field}
                      label="In USD"
                      id="cost-of-electricity"
                      style={{
                        flex: 1,
                        margin: 0,
                        minHeight: "2.5rem",
                        // padding: "16.5px 14px",
                      }}
                    />
                  )}
                />
              </StyledFormControl>
            )}
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onBack}>
          Back
        </SecondaryButton>
        <PrimaryButton
          disabled={
            !selectedOption && !costOfElectricityObserver
          }
          onClick={handleSubmit(onSubmit)}
        >
          Next
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const ReviewForm = ({
  formData,
  onSubmit: onOverallSubmit,
  onBack,
  setFormData,
}) => {
  const { priceConfig, siteConfigurationDataToEdit } =
    usePriceConfigurationContext();
  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        siteConfig:
          priceConfig?.siteConfig ||
          formData?.siteConfig ||
          [],
        priceConfigName:
          priceConfig?.priceConfigName ||
          formData?.priceConfigName ||
          "",
      },
    });

  useEffect(() => {
    if (siteConfigurationDataToEdit) {
      setValue(
        "siteConfig",
        siteConfigurationDataToEdit?.siteConfig
      );
      setValue(
        "priceConfigName",
        siteConfigurationDataToEdit?.priceConfigName
      );
    }
  }, [siteConfigurationDataToEdit]);

  const selectedOption = watch("siteConfig");
  const priceConfigName = watch("priceConfigName");

  const siteOptions = [
    "site1",
    "site2",
    "site3",
    "site4",
    "site5",
  ];

  const labelFormData = [
    {
      propertyName: "independentSystemOperator",
      value: formData?.independentSystemOperator,
      label: "Independent System Operator",
    },
    {
      propertyName: "powerPurchaseAgreement",
      value: formData?.powerPurchaseAgreement,
      label: "Power Purchase Agreement",
    },
    ...(formData?.powerPurchaseAgreement?.toLowerCase() ===
    "index"
      ? [
          {
            propertyName: "prizeZone",
            value: formData?.prizeZone,
            label: "Price Zone",
          },
        ]
      : []),
    {
      propertyName: "costOfElectricity",
      value: formData?.costOfElectricity,
      label: "Electricity Cost per MW per hour (in USD)",
    },
    ...(formData?.energyOptimizer &&
    formData?.powerPurchaseAgreement?.toLowerCase() ===
      "index"
      ? [
          {
            propertyName: "energyOptimizer",
            value: formData?.energyOptimizer
              ? "True"
              : "False",
            label: "Energy Optimizer enabled",
          },
          {
            propertyName: "turnOnPrice",
            value: formData?.turnOnPrice,
            label: "Turn On Price",
          },
          {
            propertyName: "turnOffPrice",
            value: formData?.turnOffPrice,
            label: "Turn Off Price",
          },
        ]
      : []),
  ];
  const onSubmit = (data) => {
    onOverallSubmit(data);
  };

  const handleSiteConfigDeletion = (optionToDelete) => {
    const newValue = selectedOption.filter(
      (option) => option !== optionToDelete
    );
    setValue("siteConfig", newValue);
  };

  return (
    <>
      <PriceConfigurationFormHeader
        setFormData={setFormData}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={8}
          paddingTop={"2.5rem"}
          paddingBottom={"2.5rem"}
        >
          <H8TitleMedium>
            Review the following details for your new price
            configuration and select the sites you want to
            assign it to..
          </H8TitleMedium>
          <Box
            display={"grid"}
            gridTemplateColumns={"1fr 2fr"}
            padding={"0.5rem"}
            borderRadius={"0.5rem"}
            sx={{ backgroundColor: "#E8E7F1" }}
            gap={6}
          >
            {labelFormData.map((item, index) => (
              <>
                <H9TitleSmall>{item?.label}</H9TitleSmall>
                <BodyLarge>{item?.value}</BodyLarge>
              </>
            ))}
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={8}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                className="priceConfigName"
              >
                <H8TitleMedium>
                  Enter a price configuration name
                </H8TitleMedium>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                >
                  <StyledFormControl
                    fullWidth
                    margin="none"
                  >
                    <StyledInputLabel htmlFor="site-configuration-name">
                      Price Configuration Name
                    </StyledInputLabel>
                    <Controller
                      name="priceConfigName"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <StyledOutlinedInput
                          {...field}
                          id="site-configuration-name"
                          // variant="outlined"
                          label="Enter site configuration name"
                          style={{
                            flex: 1,
                            margin: 0,
                            minHeight: "2.5rem",
                          }}
                        />
                      )}
                    />
                  </StyledFormControl>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={4}
                className="sitesContainer"
              >
                <H8TitleMedium>
                  Select Sites to Assign Price Configuration
                </H8TitleMedium>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                >
                  <StyledFormControl
                    fullWidth
                    margin="none"
                  >
                    {/* <InputLabel htmlFor="">Status</InputLabel> */}
                    <Controller
                      name="siteConfig"
                      control={control}
                      defaultValue={null}
                      render={({
                        field: { onChange, value, ref },
                      }) => (
                        <Autocomplete
                          fullWidth
                          multiple
                          disableCloseOnSelect
                          onChange={(_, data) =>
                            onChange(data)
                          }
                          value={value || []}
                          clearIcon={null}
                          options={siteOptions} // Replace with your options
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              label="Site Name"
                              variant="outlined"
                              inputRef={ref}
                              sx={{ minHeight: "2.5rem" }}
                            />
                          )}
                          renderTags={() => null}
                        />
                      )}
                    />
                  </StyledFormControl>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={4}
                  maxWidth={"62.5rem"}
                  flexWrap={"wrap"}
                >
                  {selectedOption?.map((site, index) => (
                    <PlaceHolderButton
                      key={index}
                      onClose={() =>
                        handleSiteConfigDeletion(site)
                      }
                      text={site}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onBack}>
          Back
        </SecondaryButton>
        <PrimaryButton
          disabled={!selectedOption}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </PrimaryButton>
      </DialogActions>
    </>
  );
};

const AddPriceConfigDialogModal = () => {
  const {
    openAddPriceConfigModal,
    setOpenAddPriceConfigModal,
    mockData,
    setMockData,
    activeStep,
    setActiveStep,
    totalSteps,
    setTotalSteps,
    initialFormDataForAddingPriceConfig,
    editConfigModeEnabled,
  } = usePriceConfigurationContext();

  const [configFormData, setConfigFormData] = useState(
    initialFormDataForAddingPriceConfig
  );
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();

  const onMoveForward = (data) => {
    console.log("onMoveForward data", {
      ...configFormData,
      ...data,
    });
    setConfigFormData({ ...configFormData, ...data });
    setActiveStep(activeStep + 1);
  };

  const onSubmit = (data) => {
    if (editConfigModeEnabled) {
      const index = mockData.findIndex(
        (item) =>
          item.priceConfigName.toLowerCase() ===
          data.priceConfigName.toLowerCase()
      );
      mockData[index] = { ...configFormData, ...data };
      setMockData([...mockData]);
      setToastMessage(
        "Price configuration edited successfully"
      );
      setToastOpen(true);
      setOpenAddPriceConfigModal(false);
      setConfigFormData({});
      setActiveStep(0);
      setTotalSteps(4);
    } else {
      setMockData([
        ...mockData,
        { ...configFormData, ...data },
      ]);
      setToastMessage(
        "Price configuration added successfully"
      );
      setToastOpen(true);
      setOpenAddPriceConfigModal(false);
      setConfigFormData({});
      setActiveStep(0);
      setTotalSteps(4);
    }
  };

  const onMoveBackward = () => {
    setActiveStep(activeStep - 1);
  };

  const independentSystemOperator =
    configFormData?.independentSystemOperator;

  const progress = (activeStep / totalSteps) * 100;

  return (
    <StyledDialog
      maxWidth={"lg"}
      fullWidth
      open={openAddPriceConfigModal}
      hideBackdrop
    >
      <StyledLinearProgress
        variant="determinate"
        value={progress}
        sx={{ marginBottom: "1rem" }}
      />
      {activeStep === 0 && (
        <SystemOperatorForm
          formData={configFormData}
          onNext={onMoveForward}
          setFormData={setConfigFormData}
        />
      )}
      {independentSystemOperator?.toLowerCase() ===
      "ercot" ? (
        <>
          {activeStep === 1 && (
            <PowerPurchaseAgreementForm
              formData={configFormData}
              onNext={onMoveForward}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
          {activeStep === 2 && (
            <PrizeZoneForm
              formData={configFormData}
              onNext={onMoveForward}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
          {activeStep === 3 && (
            <ReviewForm
              formData={configFormData}
              onSubmit={onSubmit}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
        </>
      ) : (
        <>
          {activeStep === 1 && (
            <PrizeZoneForm
              formData={configFormData}
              onNext={onMoveForward}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
          {activeStep === 2 && (
            <ReviewForm
              formData={configFormData}
              onSubmit={onSubmit}
              onBack={onMoveBackward}
              setFormData={setConfigFormData}
            />
          )}
        </>
      )}
    </StyledDialog>
  );
};

const AddPriceConfigDialog = (props) => {
  return (
    <>
      <AddPriceConfigDialogModal />
    </>
  );
};

export default AddPriceConfigDialog;
