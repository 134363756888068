import React from "react";
import {
  StyledIconButton,
  StyledToggleButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "@mui/icons-material/FilterList";
import { useState } from "react";
import {
  StyledDialog,
  StyledDialogTitle,
} from "../../../components/StyledComponents/Dialog/Dialog";
import {
  H6HeadlineSmall,
  H8TitleMedium,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import usePoolsPageContext from "../../context/usePoolsPageContext.js";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import { StyledMenuItem } from "../../../components/StyledComponents/Menu/Menu.tsx";
import { StyledFormControl } from "../../../components/StyledComponents/Inputs/Inputs.js";

const AddPoolConfigDialogModal = () => {
  const {
    openAddConfigModal,
    setOpenAddConfigModal,
    siteSelectedName,
    mockData,
    setMockData,
    poolDataToEdit,
    editModeOn,
    accessLists,
  } = usePoolsPageContext();
  const { setToastMessage, setToastOpen } =
    useAppContextProvider();
  const formRef = React.useRef();

  const { control, handleSubmit, watch, setValue, reset } =
    useForm({
      defaultValues: {
        accessLists: poolDataToEdit?.accessLists ?? "",
        password: poolDataToEdit?.password ?? "",
        workIdentifier:
          poolDataToEdit?.workIdentifier ?? "",
      },
    });

  React.useEffect(() => {
    if (poolDataToEdit) {
      console.log(
        "poolDataToEditInUseEffect",
        poolDataToEdit
      );
      reset({
        accessLists: poolDataToEdit.accessLists,
        password: poolDataToEdit.password,
        workIdentifier: poolDataToEdit.workIdentifier,
      });
      setValue(
        "accessLists",
        JSON.stringify(poolDataToEdit.accessLists)
      );
    }
  }, [poolDataToEdit, reset, setValue]);

  const addPoolDataToSite = (data) => {
    const dataToBeModified = [...mockData];
    const siteDataToModified = dataToBeModified?.find(
      (data) =>
        data?.label?.toLowerCase() ===
        siteSelectedName?.toLowerCase()
    );

    const poolData = siteDataToModified?.pools || [];

    let modifiedPools;
    if (poolDataToEdit?.index !== undefined) {
      // Edit existing pool data at the specified index
      modifiedPools = poolData.map((pool, index) =>
        index === poolDataToEdit.index ? data : pool
      );
      setToastMessage(
        `Edited pool ${
          poolDataToEdit.index + 1
        } for ${siteSelectedName} successfully`
      );
      setToastOpen(true);
    } else {
      // Add new pool data
      modifiedPools = [data, ...poolData];
    }

    const modifiedSiteDataWithLatestPoolInfo = {
      ...siteDataToModified,
      pools: modifiedPools,
    };

    const siteDataWithoutModifications =
      dataToBeModified?.filter(
        (site) =>
          site?.label?.toLowerCase() !==
          siteSelectedName?.toLowerCase()
      );

    setMockData(
      [
        ...siteDataWithoutModifications,
        modifiedSiteDataWithLatestPoolInfo,
      ].filter(
        (site) => site !== null || site !== undefined
      )
    );
    setToastMessage(
      `Pool configuration added to ${siteSelectedName} successfully`
    );
    setToastOpen(true);
  };
  const onSubmit = (data) => {
    const finalData = {
      ...data,
      accessLists: JSON.parse(data.accessLists),
    };
    console.log(finalData);
    addPoolDataToSite(finalData);
    setOpenAddConfigModal(false);
    reset();
  };

  return (
    <StyledDialog
      maxWidth={"lg"}
      open={openAddConfigModal}
      hideBackdrop
    >
      <StyledDialogTitle sx={{ paddingTop: 0 }}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <H6HeadlineSmall style={{ textAlign: "left" }}>
            {editModeOn
              ? `Edit pool configuration ${
                  poolDataToEdit?.index + 1
                }`
              : `Add a new configuration`}
          </H6HeadlineSmall>
          <StyledIconButton
            onClick={() => setOpenAddConfigModal(false)}
            style={{ textAlign: "right" }}
          >
            <CloseIcon />
          </StyledIconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={8}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="url-details-container"
            >
              <H8TitleMedium>Access List</H8TitleMedium>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
              >
                <StyledFormControl fullWidth margin="none">
                  <InputLabel>
                    Select Access List
                  </InputLabel>
                  <Controller
                    name="accessLists"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        sx={{ minHeight: "3.5rem" }}
                        variant="outlined"
                        label="Select AccessList"
                        fullWidth
                        value={field.value}
                        placeholder="URL"
                        // inputProps={{
                        //   sx: {
                        //     padding: "1rem 0.5rem",
                        //   },
                        // }}
                        onChange={(event) =>
                          field.onChange(event.target.value)
                        }
                      >
                        {accessLists?.map((accessList) => (
                          <StyledMenuItem
                            value={JSON.stringify(
                              accessList
                            )}
                          >
                            {`${accessList.url},${accessList.user}`}
                          </StyledMenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </StyledFormControl>
              </Box>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="url-details-container"
            >
              <H8TitleMedium>password</H8TitleMedium>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
              >
                <StyledFormControl fullWidth margin="none">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="Enter password"
                        fullWidth
                        onChange={(event) =>
                          field.onChange(event.target.value)
                        }
                      />
                    )}
                  />
                </StyledFormControl>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              className="url-details-container"
            >
              <H8TitleMedium>Work Identifier</H8TitleMedium>
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
              >
                <StyledFormControl fullWidth margin="none">
                  <InputLabel>
                    Chassis Number or IP Address
                  </InputLabel>
                  <Controller
                    name="workIdentifier"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant="outlined"
                        label="Chassis Number or IP address"
                        fullWidth
                        placeholder="URL"
                        onChange={(event) =>
                          field.onChange(event.target.value)
                        }
                      >
                        <MenuItem value={"ipAddress"}>
                          ipAddress
                        </MenuItem>
                        <MenuItem value={"chassisSerialNo"}>
                          chassisSerialNo
                        </MenuItem>
                      </Select>
                    )}
                  />
                </StyledFormControl>
              </Box>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              gap={4}
              className="action-button-container"
            >
              <PrimaryButton
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </PrimaryButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};

const AddPoolConfigDialog = (props) => {
  return (
    <>
      <AddPoolConfigDialogModal />
    </>
  );
};

export default AddPoolConfigDialog;
