import React from "react";
import { Box, IconButton, styled } from "@mui/material";

import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import TableChartIcon from "@mui/icons-material/TableChartOutlined";
import {
  BodySmall,
  H7TitleLarge,
  LabelLarge,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import {
  StyledIconButton,
  SecondaryButton,
  PrimaryButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import usePoolsPageContext from "../../context/usePoolsPageContext.js";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useHistory, Link } from "react-router-dom";
import AddPoolConfigDialog from "../AddPoolConfigDialog/AddPoolConfigDialog.js";
import { add } from "date-fns";

export const PoolsPageHeader = React.memo((props) => {
  const { data } = props;
  const {
    siteSelectedName,
    openAddConfigModal,
    setOpenAddConfigModal,
  } = usePoolsPageContext();

  const addPoolsButtonEnabled =
    data?.find(
      (site) =>
        site.label?.toLowerCase() ===
        siteSelectedName.toLowerCase()
    )?.pools.length < 3;

  console.log(
    "addPoolsButtonEnabled",
    addPoolsButtonEnabled
  );
  const history = useHistory();

  return (
    <Box
      className={"table-container-header"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        gap={2}
      >
        {siteSelectedName ? (
          <>
            <H7TitleLarge>{`${siteSelectedName} Pool configuration`}</H7TitleLarge>
            <BodySmall>
              Configure up to 3 mining pools for all miners
              in this site. The list is ordered by priority
              from highest to lowest.
            </BodySmall>
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"row"} gap={2}>
        <Box
          className={"action-container"}
          display={"flex"}
          flexDirection={"row"}
          gap={2}
        >
          <Link
            to="/pools/whitelist"
            style={{ textDecoration: "none" }}
          >
            <SecondaryButton
            // onClick={() =>
            //   history.push("/pools/whitelist/")
            // }
            >
              Whitelists
            </SecondaryButton>
          </Link>
          {siteSelectedName && (
            <>
              <PrimaryButton
                disabled={!addPoolsButtonEnabled}
                onClick={() => setOpenAddConfigModal(true)}
              >
                <Box
                  display={"flex"}
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <LabelLarge
                    sx={{
                      color: !addPoolsButtonEnabled
                        ? "#000"
                        : "#ffffff",
                    }}
                  >
                    Add
                  </LabelLarge>
                  <AddRoundedIcon
                    sx={{
                      color: !addPoolsButtonEnabled
                        ? "#000"
                        : "#ffffff",
                      opacity: !addPoolsButtonEnabled
                        ? 0.38
                        : 1,
                    }}
                  />
                </Box>
              </PrimaryButton>
            </>
          )}
        </Box>
      </Box>
      <AddPoolConfigDialog />
    </Box>
  );
});
