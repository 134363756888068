// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Container,
  ClickAwayListener,
} from "@mui/material";
import moment, { calendarFormat } from "moment";
import "reactjs-popup/dist/index.css";
import useAppContextProvider from "../AppContext/useAppContextProvider.js";
import "./DemandResponseCalendar.css";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  set,
  differenceInMinutes,
} from "date-fns";

import { useHistory, useLocation } from "react-router-dom";
import {
  BodyLarge,
  BodyMedium,
  BodySmall,
  H5HeadlineMedium,
  H6HeadlineSmall,
  H7TitleLarge,
  H8TitleMedium,
} from "../components/StyledComponents/Typography/Typography.tsx";
import enUS from "date-fns/locale/en-US";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { useQuery } from "@tanstack/react-query";
import { GetUserUseQuery } from "../api/api.js";
import {
  OutlinedButton,
  PrimaryButton,
  StyledIconButton,
} from "../components/StyledComponents/Buttons/AuradineButtons.js";
import { LabelLarge } from "../components/StyledComponents/Typography/Typography.tsx";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SmallCalendar from "./Components/SmallCalendar/SmallCalendar.js";
import {
  Calendar,
  Views,
  DateLocalizer,
  dateFnsLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import {
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  StyledMenu,
  StyledMenuItem,
} from "../components/StyledComponents/Menu/Menu.tsx";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { capitalizeString } from "../components/util.js";
import useDemandResponseContext from "./context/useDemandResponseContext.js";
import AddDemandResponseDialog from "./Components/AddDemandResponseDialog/AddDemandResponseDialog.js";
import AssignQSEDialog from "./Components/AssignQSEDialog/AssignQSEDialog.js";
import EventPopover from "./Components/EventPopover/EventPopover.js";

export const HeaderContainer = React.memo((props) => {
  const { data } = props;
  const history = useHistory();
  return (
    <Box
      className={"header-container"}
      display={"flex"}
      justifyContent="space-between"
      flexDirection="column"
      gap={4}
      marginBottom={"1.5rem"}
    >
      <Box
        className={"title-box"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={3.5}
      >
        <H7TitleLarge>
          Demand Response Configuration
        </H7TitleLarge>
        <BodyLarge>
          Manage demand response configurations by assigning
          miners to sites and QSEs, defining ramp times, and
          viewing both scheduled and manually created power
          curtailment events.
        </BodyLarge>
      </Box>
    </Box>
  );
});

const CustomCalendarHeader = (props) => {
  const { label } = props;
  const [day, date] = label?.split(" ");
  return (
    <Box
      display={"flex"}
      className="custom-header-container"
      flexDirection={"column"}
      alignItems={"center"}
      gap={1}
      marginBottom={"2.5rem"}
    >
      <BodyLarge
        className="custom"
        sx={{ color: "#7A7A7A" }}
      >
        {day}
      </BodyLarge>
      <H5HeadlineMedium
        className="custom-header-value"
        sx={{ color: "#7A7A7A" }}
      >
        {date}
      </H5HeadlineMedium>
    </Box>
  );
};

const CustomToolbarForReactBigCalendar = (props) => {
  const { onNavigate, onView, label, view, date } = props;
  const buttonRef = useRef(null);
  const [viewName, setViewName] = useState(view);
  const [anchorEl, setAnchorEl] = useState(null);
  const views = [
    { name: "day", value: "day" },
    { name: "week", value: "week" },
    { name: "month", value: "month" },
  ];

  const moveForward = () => onNavigate("NEXT");
  const moveBackward = () => onNavigate("PREV");
  const navigateToToday = () => onNavigate("TODAY");
  const handleClickAway = () => setAnchorEl(null);
  const formatDateString = (date) => {
    return view === "week" || view === "month"
      ? format(new Date(date), "MMMM yyyy")
      : format(new Date(date), "MMMM d, yyyy");
  };
  const setMenuOpen = () => {
    setAnchorEl(buttonRef.current);
  };
  const handleMenuClose = (view) => {
    onView(view);
    setViewName(view);
    setAnchorEl(null);
  };

  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"1fr 1fr"}
      justifyContent={"space-between"}
    >
      <Box
        display={"grid"}
        gridTemplateColumns={"auto auto auto 1fr"}
        gap={2}
        className="toolbar-left-container"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <OutlinedButton onClick={navigateToToday}>
          Today
        </OutlinedButton>

        <StyledIconButton onClick={moveBackward}>
          <NavigateBeforeRoundedIcon />
        </StyledIconButton>
        <StyledIconButton onClick={moveForward}>
          <NavigateNextRoundedIcon />
        </StyledIconButton>
        <H6HeadlineSmall style={{ color: "#515151" }}>
          {" "}
          {formatDateString(date)}{" "}
        </H6HeadlineSmall>
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns={"auto auto"}
        alignItems={"center"}
        justifyContent={"end"}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <>
            <OutlinedButton
              style={{ color: "#444653" }}
              ref={buttonRef}
              onClick={setMenuOpen}
              endIcon={
                Boolean(anchorEl) ? (
                  <KeyboardArrowUpRoundedIcon />
                ) : (
                  <KeyboardArrowDownRoundedIcon />
                )
              }
            >
              {capitalizeString(viewName)}
            </OutlinedButton>
            <StyledMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClickAway}
            >
              {views.map((calendarView, index) => (
                <StyledMenuItem
                  value={calendarView.value}
                  key={`menuItem${index}`}
                  selected={calendarView.value === viewName}
                  onClick={() =>
                    handleMenuClose(calendarView.value)
                  }
                  sx={{ minWidth: "7rem" }}
                >
                  {capitalizeString(calendarView.name)}
                </StyledMenuItem>
              ))}
            </StyledMenu>
          </>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};

const QSEAssignmentContainer = () => {
  const { setOpenQSEDialog } = useDemandResponseContext();
  const { register, watch } = useForm({
    defaultValues: {
      voltusEventsShown: true,
      cPowerEventsShown: true,
      powerCurtailOperationShown: true,
    },
  });

  // Watch the values of the checkboxes
  const voltusEventsShown = watch("voltusEventsShown");
  const cPowerEventsShown = watch("cPowerEventsShown");
  const powerCurtailOperationShown = watch(
    "powerCurtailOperationShown"
  );

  return (
    <Box
      display={"grid"}
      gridTemplateRows={"auto auto auto"}
      gap={2}
    >
      <H8TitleMedium>Calendars</H8TitleMedium>
      <Box
        display={"grid"}
        gridTemplateRows={"auto auto auto"}
        gap={2}
      >
        <FormControlLabel
          control={
            <Checkbox
              {...register("voltusEventsShown")}
              checked={voltusEventsShown}
              sx={{
                color: "#7857FF",
                "&.Mui-checked": {
                  color: "#7857FF",
                },
              }}
            />
          }
          label={<BodyMedium>Voltus</BodyMedium>}
        />
        <FormControlLabel
          control={
            <Checkbox
              {...register("cPowerEventsShown")}
              checked={cPowerEventsShown}
              sx={{
                color: "#3D9DF5",
                "&.Mui-checked": {
                  color: "#3D9DF5",
                },
              }}
            />
          }
          label={<BodyMedium>CPower</BodyMedium>}
        />
        <FormControlLabel
          control={
            <Checkbox
              {...register("powerCurtailOperationShown")}
              checked={powerCurtailOperationShown}
              sx={{
                color: "#2D55C9",
                "&.Mui-checked": {
                  color: "#2D55C9",
                },
              }}
            />
          }
          label={
            <BodyMedium>
              Power Curtailment Operation
            </BodyMedium>
          }
        />
      </Box>
      <Box>
        <OutlinedButton
          startIcon={
            <span className="material-symbols-rounded">
              list_alt_add
            </span>
          }
          onClick={() => setOpenQSEDialog(true)}
        >
          Assign
        </OutlinedButton>
      </Box>
    </Box>
  );
};

const CustomEvent = ({ event, currentView }) => {
  const diffInMin = differenceInMinutes(
    event.end,
    event.start
  );

  return (
    <>
      {currentView !== "month" && diffInMin > 45 ? (
        <div display={"flex"} justifyContent={"flex-start"}>
          <BodySmall style={{ color: "#fff" }}>
            {event?.title}
          </BodySmall>
          <BodySmall style={{ color: "#fff" }}>
            {`${format(event.start, "h:mm a")} - ${format(
              event.end,
              "h:mm a"
            )}`}
          </BodySmall>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <BodySmall style={{ color: "#fff" }}>{`${
            event?.title
          }, ${format(event.start, "h:mm a")}`}</BodySmall>
        </div>
      )}
    </>
  );
};

/**
 * Represents the DemandResponse page component.
 * This component displays a table of miner devices and provides functionality to interact with them.
 */
const DemandResponseConfigurationPage = () => {
  const calendarRef = useRef(null);
  const eventRef = useRef(null);
  const {
    selectedDate,
    calendarEvents,
    openPowerCurtailOperationModal,
    setOpenPowerCurtailOperationModal,
    eventModalData,
    setEventModalData,
    setEventModalAnchor,
  } = useDemandResponseContext();
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: { enUS },
  });

  const eventPropGetter = (event) => {
    const backgroundColor = event.color || "#3174ad"; // Default color if no custom color is provided
    return {
      style: {
        backgroundColor,
        borderRadius: "0.25rem",
        border: "none",
        // padding: "0.5rem 0rem 0.9375rem 0.5rem",
      },
    };
  };

  const formats = {
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, "EEE dd", culture),
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, "EEE", culture),
    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(date, "ha", culture), // Custom format for column headers
  };

  useEffect(() => {
    const startOfWeekForSelectedDate = startOfWeek(
      selectedDate,
      { weekStartsOn: 0 }
    );
    if (calendarRef.current.props) {
      console.log(
        "calendarRef.current",
        calendarRef.current
      );
      if (
        calendarRef.current.props.view === "week" ||
        calendarRef.current.props.view === "month"
      ) {
        calendarRef.current?.props?.onNavigate(
          startOfWeekForSelectedDate
        );
      } else {
        calendarRef.current?.props?.onNavigate(
          selectedDate
        );
      }
    }
  }, [selectedDate]);

  const handleSelectEvent = (props, event) => {
    // setEventModalData(props);
    console.log("props", props, event.currentTarget);
    setEventModalData(props);
    setEventModalAnchor(event.currentTarget);
  };

  return (
    //@ts-nocheck
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <HeaderContainer />
      <Box
        display={"grid"}
        gridTemplateColumns={"0.8fr 3.2fr"}
        sx={{ height: "calc(100vh - 12rem)" }}
        className="demand-response-page-container"
      >
        <Box
          display={"grid"}
          className="leftSideControlsContainer"
          sx={{ height: "calc(100vh - 12rem)" }}
          rowGap={6}
          gridTemplateRows={"0.1fr 0.8fr 2fr"}
        >
          <Box
            display={"grid"}
            gridTemplateColumns="repeat(3, 1fr)"
            className="createButtonContainer"
          >
            <PrimaryButton
              sx={{
                gridColumn: "1/ span 1",
                width: "7.13rem",
              }}
              onClick={() =>
                setOpenPowerCurtailOperationModal(true)
              }
            >
              <Box display={"flex"} sx={{ gap: "0.5rem" }}>
                Create
                <AddRoundedIcon />
              </Box>
            </PrimaryButton>
          </Box>
          <Box
            display={"grid"}
            className="smallCalendarContainer"
            justifyContent={"start"}
            // gap={6}
          >
            <SmallCalendar />
            <QSEAssignmentContainer />
          </Box>
        </Box>
        <Box
          display={"grid"}
          className="rightSideCalendarContainer"
          gridTemplateRows={"fr"}
          style={{
            backgroundColor: "#F3F2FD",
            borderRadius: "1rem",
            padding: "1.5rem",
          }}
        >
          <Calendar
            ref={calendarRef}
            style={{ height: "calc(100vh - 15rem)" }}
            defaultDate={Date.now()}
            events={calendarEvents}
            // max={max}
            localizer={localizer}
            step={60}
            timeslots={1}
            views={["day", "week", "month"]}
            min={new Date(1970, 1, 1, 0, 0, 0)} // Start time at 12:00 AM
            max={new Date(1970, 1, 1, 23, 59, 59)}
            allDayAccessor={null}
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleSelectEvent}
            formats={formats}
            components={{
              event: (props) => (
                <CustomEvent
                  {...props}
                  currentView={
                    calendarRef.current?.props.view
                  }
                />
              ),
              toolbar: CustomToolbarForReactBigCalendar,
              day: {
                header: CustomCalendarHeader,
              },
              week: {
                header: CustomCalendarHeader,
              },
            }}
          />
        </Box>
      </Box>
      <EventPopover />
      <AddDemandResponseDialog />
      <AssignQSEDialog />
    </Container>
  );
};

export default DemandResponseConfigurationPage;
