//@ts-nocheck
import React, { useState } from "react";
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Input,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import {
  OutlinedButton,
  PrimaryButton,
  SecondaryButton,
  StyledIconButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  BodyLarge,
  H6HeadlineSmall,
  H8TitleMedium,
  DataLegend,
  LabelLarge,
} from "../../../components/StyledComponents/Typography/Typography.tsx";
import HistoryIcon from "@mui/icons-material/History";
import DataGrid from "../../../components/DataGrid/DataGrid.tsx";
import StyledSwitch from "../../../components/StyledComponents/Switch/Switch.js";
import NotificationsDialog from "../../Components/NotificationsDialog/NotificationsDialog";
import { useHistory } from "react-router-dom";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";

const createNotificationsData = () => {
  const hashboardChipMockData = [];
  const users = [
    "John Doe",
    "Jane Smith",
    "Mike Johnson",
    "Emily Davis",
    "David Brown",
  ];
  const emails = [
    "john.doe@example.com",
    "jane.smith@example.com",
    "mike.johnson@example.com",
    "emily.davis@example.com",
    "david.brown@example.com",
  ];

  for (let i = 0; i < 10; i++) {
    const userIndex = Math.floor(
      Math.random() * users.length
    );
    const emailIndex = Math.floor(
      Math.random() * emails.length
    );
    const startTime = new Date();
    const lastModified = new Date(
      startTime.getTime() -
        Math.floor(Math.random() * 1000000)
    );
    const active = Math.random() < 0.5;

    hashboardChipMockData.push({
      id: i,
      user: users[userIndex],
      email: emails[emailIndex],
      startTime: startTime.toISOString(),
      lastModified: lastModified.toISOString(),
      active: active,
    });
  }

  return hashboardChipMockData;
};

const HashBoardsMalfunctionTable = (props) => {
  const { datagridProps } = props;
  const { setToastOpen, setToastMessage } =
    useAppContextProvider();
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      gap={4}
      marginTop={4}
    >
      <BodyLarge>
        When there is a hash board malfunction, the
        following users have notifications sent to them at
        the set interval.
      </BodyLarge>
      <Box
        display="flex"
        flexDirection={"row"}
        gap={2}
        alignItems={"center"}
      >
        <H8TitleMedium>
          Send notifications every:
        </H8TitleMedium>
        <TextField
          id="outlined-basic"
          label="Minutes"
          variant="outlined"
        />
        <OutlinedButton
          onClick={() => {
            setToastMessage(
              " Notification frequency saved successfully"
            );
            setToastOpen("true");
          }}
        >
          Save
        </OutlinedButton>
        <SecondaryButton
          endIcon={
            <HistoryIcon fontSize="small" color="#2D55C9" />
          }
        >
          Notifications
        </SecondaryButton>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-end"}
      >
        <SecondaryButton>Unsubscribe</SecondaryButton>
      </Box>
      <Box
        className={"Datagrid-container"}
        display={"flex"}
        flexShrink={1}
      >
        <DataGrid config={datagridProps} height={"47vh"} />
      </Box>
    </Box>
  );
};
const PSUMalfunctionTable = (props) => {
  const { datagridProps } = props;
  const { setToastOpen, setToastMessage } =
    useAppContextProvider();
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      gap={4}
      marginTop={4}
    >
      <BodyLarge>
        When there is a PSU malfunction, the following users
        have notifications sent to them at the set interval.
      </BodyLarge>
      <Box
        display="flex"
        flexDirection={"row"}
        gap={2}
        alignItems={"center"}
      >
        <H8TitleMedium>
          Send notifications every:
        </H8TitleMedium>
        <TextField
          id="outlined-basic"
          label="Minutes"
          variant="outlined"
        />
        <OutlinedButton
          onClick={() => {
            setToastMessage(
              " Notification frequency saved successfully"
            );
            setToastOpen("true");
          }}
        >
          Save
        </OutlinedButton>
        <SecondaryButton
          endIcon={
            <HistoryIcon fontSize="small" color="#2D55C9" />
          }
        >
          Notifications
        </SecondaryButton>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-end"}
      >
        <SecondaryButton>Unsubscribe</SecondaryButton>
      </Box>
      <Box
        className={"Datagrid-container"}
        display={"flex"}
        flexShrink={1}
      >
        <DataGrid config={datagridProps} height={"47vh"} />
      </Box>
    </Box>
  );
};
const FanMalfunctionTable = (props) => {
  const { datagridProps } = props;
  const { setToastOpen, setToastMessage } =
    useAppContextProvider();
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      gap={4}
      marginTop={4}
    >
      <BodyLarge>
        When there is a fan malfunction, the following users
        have notifications sent to them at the set interval.
      </BodyLarge>
      <Box
        display="flex"
        flexDirection={"row"}
        gap={2}
        alignItems={"center"}
      >
        <H8TitleMedium>
          Send notifications every:
        </H8TitleMedium>
        <TextField
          id="outlined-basic"
          label="Minutes"
          variant="outlined"
        />
        <OutlinedButton
          onClick={() => {
            setToastMessage(
              " Notification frequency saved successfully"
            );
            setToastOpen("true");
          }}
        >
          Save
        </OutlinedButton>
        <SecondaryButton
          endIcon={
            <HistoryIcon fontSize="small" color="#2D55C9" />
          }
        >
          Notifications
        </SecondaryButton>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-end"}
      >
        <SecondaryButton>Unsubscribe</SecondaryButton>
      </Box>
      <Box
        className={"Datagrid-container"}
        display={"flex"}
        flexShrink={1}
      >
        <DataGrid config={datagridProps} height={"47vh"} />
      </Box>
    </Box>
  );
};

const ChipMalfunctionTable = (props) => {
  const { datagridProps } = props;
  const { setToastOpen, setToastMessage } =
    useAppContextProvider();

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      gap={4}
      marginTop={4}
    >
      <BodyLarge>
        When there is a chip malfunction, the following
        users have notifications sent to them at the set
        interval.
      </BodyLarge>
      <Box
        display="flex"
        flexDirection={"row"}
        gap={2}
        alignItems={"center"}
      >
        <H8TitleMedium>
          Send notifications every:
        </H8TitleMedium>
        <TextField
          id="outlined-basic"
          label="Minutes"
          variant="outlined"
        />
        <OutlinedButton
          onClick={() => {
            setToastMessage(
              " Notification frequency saved successfully"
            );
            setToastOpen("true");
          }}
        >
          Save
        </OutlinedButton>

        <SecondaryButton
          endIcon={
            <HistoryIcon fontSize="small" color="#2D55C9" />
          }
        >
          Notifications
        </SecondaryButton>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-end"}
      >
        <SecondaryButton>Unsubscribe</SecondaryButton>
      </Box>
      <Box
        className={"Datagrid-container"}
        display={"flex"}
        flexShrink={1}
      >
        <DataGrid config={datagridProps} height={"47vh"} />
      </Box>
    </Box>
  );
};

const NotificationsPageHeader = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useHistory();
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2.48}
      marginBottom={"1.5rem"}
    >
      <Box
        display="flex"
        flexDirection="column"
        minWidth={"2.98rem"}
        justifyContent="flex-start"
        alignItems={"center"}
      >
        <StyledIconButton onClick={() => navigate.goBack()}>
          {" "}
          <ArrowBackIosNewIcon
            fontSize="mediun"
            color="#000"
          />{" "}
        </StyledIconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={"space-between"}
        flexGrow={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          justifyContent="flex-start"
        >
          <H6HeadlineSmall>Notifications</H6HeadlineSmall>
          <BodyLarge>
            View and edit notifications for each user.
          </BodyLarge>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          minWidth={"2.98rem"}
          justifyContent="flex-end"
          alignItems={"center"}
        >
          <PrimaryButton
            endIcon={
              <AddIcon
                fontSize="small"
                sx={{ color: "white" }}
              />
            }
            onClick={() => setOpen(true)}
          >
            Add{" "}
          </PrimaryButton>
        </Box>
      </Box>
      <NotificationsDialog
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
};

const NotificationsPage = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const tableRows = createNotificationsData();
  const navigate = useHistory();

  const [rowData, setRowData] = useState(tableRows);
  const handleSwitchChange = (event, id) => {
    event.stopPropagation();
    setRowData((prevRows) =>
      prevRows.map((row) =>
        row?.id === id
          ? { ...row, active: !row.active }
          : row
      )
    );
  };
  let columns = [
    {
      field: "user",
      headerName: "User",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "startTime",
      headerName: "Start time",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "lastModified",
      headerName: "Last modified",
      minWidth: 150,
      flex: 1,
    },

    {
      field: "active",
      headerName: "Active",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexDirection={"row"}>
            <StyledSwitch
              checked={params.value}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) =>
                handleSwitchChange(event, params.id)
              }
            />
          </Box>
        );
      },
    },
  ];

  const dataGridProps = {
    rows: Array.isArray(rowData) ? rowData : [],
    columns: [...columns],

    // pageSize,
    page: 0,
    paginationMode: "client",
    rowCount: Array.isArray(rowData) ? tableRows.length : 0,
    // onPageSizeChange: handlePageSizeChange,
    // rowsPerPageOptions: [25, 50, 100],
    getRowId: (row) => row.id,
    pagination: true,
    pageSize: rowData.length,
    disableColumnMenu: true,
    disableColumnResize: true,
    checkboxSelection: true,
    disableSelectionOnClick: true,
    disableCellSelection: true,
  };

  return (
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <NotificationsPageHeader />
      <Box
        className={"miner-page-table"}
        display={"flex"}
        flexDirection={"column"}
        backgroundColor={"#F3F2FD"}
        padding={"0.75rem 0.75rem 0rem 0.75rem"}
        borderRadius={"1rem"}
      >
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) =>
            setSelectedTab(newValue)
          }
          variant="fullWidth"
          textColor="inherit"
          indicatorColor="primary"
          disableRipple
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              color: "#444653",
              fontFamily: "Roboto",
              fontSize: "1rem",
              fontWeight: "500",
              lineHeight: "1.25rem",
            },
            "& .Mui-selected": {
              color: "#2D55C9", // Custom text color for selected tab
            },
            "& .MuiTabs-indicator": {
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#2D55C9",
              maxWidth: "5%",
              height: "4px",
              borderTopLeftRadius: 100,
              borderTopRightRadius: 100,
              marginLeft: "10%",
            },
            "& .MuiTabs-indicatorSpan": {
              maxWidth: " 10 !important",
              width: "100%",
              backgroundColor: "#2D55C9",
            },
          }}
        >
          <Tab label="Hashboard malfunction" />
          <Tab label="Fan malfunction" />
          <Tab label="PSU malfunction" />
          <Tab label="Chip malfunction" />
        </Tabs>

        {selectedTab === 0 && (
          <HashBoardsMalfunctionTable
            datagridProps={dataGridProps}
          />
        )}
        {selectedTab === 1 && (
          <FanMalfunctionTable
            datagridProps={dataGridProps}
          />
        )}
        {selectedTab === 2 && (
          <PSUMalfunctionTable
            datagridProps={dataGridProps}
          />
        )}
        {selectedTab === 3 && (
          <ChipMalfunctionTable
            datagridProps={dataGridProps}
          />
        )}
      </Box>
    </Container>
  );
};

export default NotificationsPage;
