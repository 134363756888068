// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Paper,
  Tooltip,
  InputBase,
  IconButton,
  LinearProgress,
  ClickAwayListener,
} from "@mui/material";
import "reactjs-popup/dist/index.css";
import useAppContextProvider from "../../../AppContext/useAppContextProvider.js";
import usePoolsPageContext from "../../context/usePoolsPageContext.js";
import { useHistory, useLocation } from "react-router-dom";
import {
  BodyLarge,
  H7TitleLarge,
} from "../../../components/StyledComponents/Typography/Typography.tsx";

import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import {
  PoolsPageContext,
  PoolsPageContextProvider,
} from "../../context/PoolsPageContextProvider.js";

import DataGrid from "../../../components/DataGrid/DataGrid.tsx";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../Components/PoolCard/PoolCard.js";

import {
  PrimaryButton,
  StyledIconButton,
} from "../../../components/StyledComponents/Buttons/AuradineButtons.js";
import MoreVertIconRounded from "@mui/icons-material/MoreVertRounded";
import SearchIcon from "@mui/icons-material/Search";
import { renderActionsCell } from "@mui/x-data-grid";
import { set } from "date-fns";
import ActionDialog from "../../../components/ActionDialog/ActionDialog.js";
import { AddAccessListsDialog } from "../../Components/AddAccessListsDialog/AddAccessListsDialog.js";

export const WhiteListsMenu = (props) => {
  const { rowInfo } = props;
  console.log(rowInfo);
  const {
    setEditWhiteListsOn,
    setOpenWhiteListsDeletionDialog,
    setDeletionHeaderMessage,
    setDeletionSubHeaderMessage,
    setWhiteListToDelete,
    setOpenAccessListsDialog,
  } = usePoolsPageContext();

  const buttonRef = React.useRef(null);
  const [anchorEl, setMenuAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(buttonRef.current);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (action, row) => {
    switch (action) {
      case "edit":
        setMenuAnchorEl(null);
        setEditWhiteListsOn(true);
        setWhiteListToDelete(rowInfo);
        setOpenAccessListsDialog(true);
        break;
      case "delete":
        setMenuAnchorEl(null);
        setOpenWhiteListsDeletionDialog(true);
        setDeletionHeaderMessage(
          `Delete from your Access Trust List?`
        );
        setDeletionSubHeaderMessage(
          `Are you sure you want to delete ${rowInfo.user},${rowInfo.url} ?This action cannot be undone.`
        );
        setWhiteListToDelete(rowInfo);
        break;
      default:
        return;
    }
  };

  return (
    <>
      <StyledIconButton
        ref={buttonRef}
        // id={`more-vert${params.row.id}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIconRounded />
      </StyledIconButton>
      <ClickAwayListener onClickAway={handleMenuClose}>
        <StyledMenu
          // sx={{ boxShadow: "none !important" }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 1,
            sx: {
              "&.MuiPaper-root": {
                boxShadow:
                  "0px 1px 1px 0px lightgrey, 0px 1px 1px 1px lightgrey",
              },
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <StyledMenuItem
            onClick={() =>
              handleMenuItemClick("edit", rowInfo)
            }
          >
            Edit
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() =>
              handleMenuItemClick("delete", rowInfo)
            }
          >
            Delete
          </StyledMenuItem>
        </StyledMenu>
      </ClickAwayListener>
    </>
  );
};

export const HeaderContainer = React.memo((props) => {
  const { data } = props;

  const history = useHistory();

  return (
    <Box
      className={"header-container"}
      display={"flex"}
      justifyContent="space-between"
      flexDirection="column"
      gap={4}
      marginBottom={"1.5rem"}
    >
      <Box
        className={"title-box"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={3.5}
      >
        <H7TitleLarge>{`Access Trust List`}</H7TitleLarge>
        <BodyLarge>
          Manage domains and users outside your
          organization's domain that are trusted to access
          FluxVision.
        </BodyLarge>
      </Box>
    </Box>
  );
});

const WhiteListsPageHeader = () => {
  const {
    accessLists,
    setAccessLists,
    accessListsCopy,
    setOpenAccessListsDialog,
  } = usePoolsPageContext();

  const handleDialogOpen = () => {
    setOpenAccessListsDialog(true);
  };

  return (
    <Box
      display={"grid"}
      justifyContent={"space-between"}
      gridTemplateColumns={"1fr 1fr"}
    >
      <Box display={"grid"} gridTemplateColumns={"2fr 1fr"}>
        <Paper
          component="form"
          elevation={0}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#E8E7F1",
            borderRadius: "1.75rem",
            paddingLeft: "1rem",
          }}
        >
          <Tooltip
            // title={searchValue}
            disableFocusListener
            disableTouchListener
          >
            <InputBase
              sx={{ flex: 1, minWidth: "22.5rem" }}
              placeholder="Search for user or URL"
              onChange={(event) => {
                if (event.target.value) {
                  const changedAccessLists =
                    accessLists.filter((item) => {
                      return item.user.includes(
                        event.target.value
                      );
                    });
                  setAccessLists(changedAccessLists);
                } else {
                  setAccessLists(accessListsCopy);
                }
              }}
              inputProps={{
                "aria-label": "search google maps",
              }}
            />
          </Tooltip>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      <Box display={"grid"} justifyContent={"end"}>
        <PrimaryButton onClick={handleDialogOpen}>
          Add
        </PrimaryButton>
      </Box>
    </Box>
  );
};

/**
 * Represents the MinerPage component.
 * This component displays a table of miner devices and provides functionality to interact with them.
 */
function WhiteListsPageContent() {
  const tableContainerRef = React.useRef(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const {
    accessLists,
    setAccessLists,
    setAccessListsCopy,
    openWhiteListsDeletionDialog,
    setOpenWhiteListsDeletionDialog,
    deletionHeaderMessage,
    deletionSubHeaderMessage,
    whiteListToDelete,
  } = usePoolsPageContext();

  const { setToastMessage, setToastOpen } =
    useAppContextProvider();

  const handleDeletionDialogClose = () => {
    setOpenWhiteListsDeletionDialog(false);
  };

  const handleDeleteAction = () => {
    console.log("Delete action");
    const accessCopy = [...accessLists];
    const filteredDeleteValue = accessCopy.filter(
      (access) => access.id !== whiteListToDelete.id
    );
    setAccessLists([...filteredDeleteValue]);
    setAccessListsCopy([...filteredDeleteValue]);
    setToastMessage("Successfully deleted the access list");
    setToastOpen(true);
    setOpenWhiteListsDeletionDialog(false);
  };

  const testResult = Array.from(
    { length: 100 },
    (_, index) => ({
      id: index + 1,
      user: `miningRobot${index + 1}`,
      url: `http://stratum${index + 1}`,
    })
  );

  useEffect(() => {
    setAccessLists(testResult);
    setAccessListsCopy(testResult);
  }, []);

  const columns = [
    {
      field: "user",
      headerName: "User",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "url",
      headerName: "URL",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "",
      headerName: "",
      width: 20,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <></>;
      },
      renderCell: (params) => {
        return (
          <>
            <WhiteListsMenu rowInfo={params.row} />
          </>
        );
      },
    },
  ];

  const dataGridProps = {
    rows: accessLists,
    columns,
    disableColumnMenu: true,
    hideFooterPagination: true,
    hideFooter: true,
    disableSelectionOnClick: true,
    disableCellSelection: true,
  };

  return (
    <Container
      component="div"
      maxWidth="100%"
      sx={{
        padding: "2rem 3.5rem !important ",
        margin: 0,
        backgroundColor: "#FAF8FF",
        // overflowY: "auto",
        height: "100vh",
      }}
    >
      <HeaderContainer />
      <Grid
        container
        className={"miner-page-table-container"}
        display={"flex"}
        flexDirection={"row"}
        spacing={4}
        sx={{ transition: "all 0.3s ease-in-out" }}
      >
        <Grid
          ref={tableContainerRef}
          item
          xs={12}
          sx={{
            transition: "width 0.3s ease-in-out", // Smooth transition for width
          }}
        >
          <Box
            className={"miner-page-table"}
            display={"flex"}
            flexDirection={"row"}
            backgroundColor={"#F3F2FD"}
            padding={"0.75rem 0  0.75rem 0"}
            borderRadius={"1rem"}
            sx={{
              transition: "width 0.3s ease-in-out", // Smooth transition for width
            }}
          >
            <Box
              className={"table-container"}
              display={"flex"}
              flexDirection={"column"}
              padding={"0 0.75rem 0 0.75rem"}
              height={"auto"}
              // overflow={"auto"}
              minWidth={"0"}
              gap={4}
              flex={3}
              flexShrink={1}
            >
              <WhiteListsPageHeader />
              <Box
                className={"Datagrid-container"}
                display={"flex"}
                flexShrink={1}
                flexGrow={1}
                overflowY={"auto"}
                height="calc(100vh - 17rem)"
              >
                <DataGrid
                  config={dataGridProps}
                  height="calc(100vh - 19rem)"
                />
              </Box>
            </Box>
            {openWhiteListsDeletionDialog && (
              <ActionDialog
                open={openWhiteListsDeletionDialog}
                onClose={handleDeletionDialogClose}
                headerMessage={deletionHeaderMessage}
                subMessage={deletionSubHeaderMessage}
                buttonText={"delete"}
                deleteAction={handleDeleteAction}
              />
            )}
            <AddAccessListsDialog />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default function WhiteListsPage() {
  return (
    <PoolsPageContextProvider>
      <WhiteListsPageContent />
    </PoolsPageContextProvider>
  );
}
